import React from "react";
import { ErrorMessage, Field } from "formik";
import TextField from "@material-ui/core/TextField";
import { FormikFieldProps } from "../../../adapter/usecase/Formik";

const FormikField: React.FC<FormikFieldProps> = ({
  name,
  label,
  type,
  required,
}) => {
  return (
    <div className="FormikField">
      <Field
        required={required}
        autoComplete="off"
        as={TextField}
        label={label}
        name={name}
        fullWidth
        type={type}
        helperText={<ErrorMessage name={name} />}
      />
    </div>
  );
};

export default FormikField;
