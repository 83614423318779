import React, { useEffect, useState, useMemo, useLayoutEffect } from "react";
import { TableHeader, Pagination, Search } from "../../DataTable";
import UserListUseCaseHanlder from "../../../usecase/UserListUseCaseHandler";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Loader from "../../Utils/Loader";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import Card from '@material-ui/core/Card';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from 'react-router-dom';
import TradeImportComponent from "../Dashboard/AddFile";
import {
  Grid,
  Paper,
  Button,
  TextField,
} from "@material-ui/core/";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import axios from "axios";

const AgentMaster = (props) => {
  const [datas, setDatas] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [editaBtnClicked, setEditaBtnClicked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [publishModal, setPublishModal] = React.useState(false);

  const [notification, setNofication] = React.useState(true);
  const handlePublishChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNofication(event.target.checked);
  };

  // const handleModifyChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setNofication(event.target.checked);
  // };
  const handleCloseChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNofication(event.target.checked);
  };




  const [
    fairPriceValidationMessage,
    setFairPriceValidationMessage,
  ] = React.useState("");

  const [
    expectedReturnInDurationError,
    setExpectedReturnInDurationError,
  ] = React.useState("");

  const [
    expectedReturnInPercentageError,
    setExpectedReturnInPercentageError,
  ] = React.useState("");


  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [editableData, setEditableData] = useState({
  brokerInv19Id: '',
  brokerName : '',
  brokerNseMemberId : '',
  brokerBseMemberId : '',
  brokerNseExchName : '',
  brokerBseExchName : '',
  brokerNseTradingId : '',
 brokerBseTradingId : '',
 brokerWebsiteUrl : '',
 brokerBackofficeName : '',
 brokerBackofficeUrl : '',
 brokerRegisterOffAdd : '',
 brokerRegisterOffCity : '',
 brokerRegisterOffState : '',
 brokerRegisterOffPin : '',
 brokerRegisterPhone : '',
 brokerRegisterEmail : '',
 brokerRegisterFax : '',
 brokerCorrespondOffAdd : '',
 brokerCorrespondOffCity :  '',
 brokerCorrespondOffState : '',
 brokerCorrespondOffPin :  '',
 brokerCorrespondPhone : '',
 brokerCorrespondEmail :  '',
 brokerCorrespondFax : '',
 brokerComplianceOfficerName :  '',
 brokerComplianceOfficerPhone :  '',
 brokerComplianceOfficerEmail :  '',
 brokerCeoName :  '',
 brokerCeoPhone :  '',
 brokerCeoEmail : '',
 brokerClearingMemberInfo : '',
 brokerSebiRegNoNse : '',
 brokerSebiRegNoBse : '',
 brokerGrievanceCellInfo : '',
 brokerCustomercareNo : '',
 brokerCustomercareEmail : '',
 brokerDpTypeNsdl : '',
 brokerDpStatusNsdl :  '',
 brokerDpNameNsdl :  '',
 brokerDpAddressNsdl :  '',
 brokerDpCityNsdl :  '',
 brokerDpStateNsdl :  '',
 brokerDpPinNsdl :  '',
 brokerDpPhoneNsdl :  '',
 brokerDpEmailNsdl :  '',
 brokerDpWebsiteNsdl :  '',
 brokerDpComplianceNameNsdl :  '',
 broker_dpCompliancePhoneNsdl :  '',
 brokerDpComplianceEmailNsdl :  '',
 brokerDpTypeCdsl :  '',
 brokerDpStatusCdsl : '',
 brokerDpNameCdsl :  '',
 brokerDpAddressCdsl : '',
 brokerDpCityCdsl :  '',
 brokerDpStateCdsl :  '',
 brokerDpPinCdsl :  '',
 brokerDpPhoneCdsl :  '',
 brokerDpEmailCdsl :  '',
 brokerDpWebsiteCdsl :  '',
 brokerDpComplianceNameCdsl :  '',
 brokerDpCompliancePhoneCdsl :  '',
 brokerDpComplianceEmailCdsl :  '',
 brokerLogoUrl :  '',
 brokerCreatedBy :  '',
 brokerUpdatedBy : '',
 brokerClearingMemberName :  '',
 brokerClearingMemberAddr :  '',
 brokerClearingMemberCity :  '',
 brokerClearingMemberState :  '',
 brokerClearingMemberPin :  '',
 brokerClearingMemberPhone :  '',
 brokerClearingMemberFax : '',
 brokerClearingMemberEmail :  '',
 brokerClearingMemberWebsite :  '',
 brokerDPId : '',
 brokerSebiRegBseDate :  '',
 brokerSebiRegDpDate :  '',
 brokerSebiRegNoDp : '',
 brokerSebiRegNseDate :  '',
 brokerShortName : '',
 brokerSebiRegNo :  '',
 bseCeoEmail :  '',
 bseCeoName : '',
 bseCeoPhone :  '',
 nseCeoEmail :  '',
 nseCeoName : '',
 nseCeoPhone :  '',
 brokerStarRating : '',
 brokerRecommendedRating : '',
 registrationNoNsdl : '',
 registrationDateNsdl : '',
 registrationNoCdsl :  '',
 registrationDateCdsl : '',
 currentValue : '',
 endValue : '',
 incrementBy : '',
 seedValue : '',
 series :  '',
 brokerUccCurrentValue : '',
 brokerUccEndValue : '',
 brokerUccIncrementBy : '',
 brokerUccSeedValue : '',
 brokerUccSeries : '',
 brokerUpcomingFlag : '',
 clearingMemberCinNo : '',
 clearingMemberSebiRegNo : '',
 brokerGrievanceEmailId : '',
 brokerKycEmailId : '',
 boId : '',
 brokerCdslEdisFlag :  '',
 brokerStampUrl : '',
 brokerMailHost : '',
 brokerMailId : '',
 brokerMailPassword : '',
 brokerMailPort : '',
  });
  



  const [userMetaInfo, setUserMetaInfo] = useState({
    addCall: "",
    adminRollType: "",
    closedCall: "",
    createdAt: "",
    modifyCall: "",
    publishCall: "",
  });


  const [meta, setMeta] = useState(false);

  const ITEMS_PER_PAGE = 10;

  const { allDatas, fetchedUserList, statusCode, message } = useSelector(
    (state: any) => ({
      allDatas: state.userListsReducer.allData,
      fetchedUserList: state.userListsReducer.fetchedUserList,
      message: state.userListsReducer.message,
      statusCode: state.userListsReducer.statusCode,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const onHandlerKeyUpExp = (event) => {
    // alert("test");
    if (event.target.name) {
      if (!isNaN(event.target.value)) {
        let num = Number(event.target.value);
        if (typeof num == "number") {
          if (num % 1 != 0) {
            setExpectedReturnInPercentageError("Decimal value not allowed");
          } else {
            setExpectedReturnInPercentageError("");
          }
        }
      }
    }
  };

  const onHandlerKeyUpReturnInDuration = (event) => {
    if (event.target.name) {
      if (!isNaN(event.target.value)) {
        let num = Number(event.target.value);
        if (typeof num == "number") {
          if (num % 1 != 0) {
            setExpectedReturnInDurationError("Decimal value not allowed");
          } else {
            setExpectedReturnInDurationError("");
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(UserListUseCaseHanlder.getbrokerCallsData());
  }, []);

  useEffect(() => {
    setDatas(allDatas);
  }, [allDatas]);

  useEffect(() => {
    let username = localStorage.getItem("username");
  }, []);

  const editUser = (data: any) => {
    // alert(JSON.stringify(data));
    setOpen(true);
    setEditaBtnClicked(true);
    setEditableData(data);
  };

  const handleChange = (e: any) => {
    setEditableData({ ...editableData, [e.target.name]: e.target.value });
  };
  


  const handleSubmit = (e: any) => {
    e.preventDefault();
    // alert(editableData);
    let data = {

        brokerInv19Id:  editableData.brokerInv19Id,
        brokerName :  editableData.brokerName,
         brokerNseMemberId :  editableData.brokerNseMemberId,
         brokerBseMemberId :  editableData.brokerBseMemberId,
         brokerNseExchName :  editableData.brokerNseExchName,
         brokerBseExchName :  editableData.brokerBseExchName,
         brokerNseTradingId :  editableData.brokerNseTradingId,
         brokerBseTradingId :  editableData.brokerBseTradingId,
         brokerWebsiteUrl :  editableData.brokerWebsiteUrl,
         brokerBackofficeName :  editableData.brokerBackofficeName,
         brokerBackofficeUrl :  editableData.brokerBackofficeUrl,
         brokerRegisterOffAdd :  editableData.brokerRegisterOffAdd,
         brokerRegisterOffCity :  editableData.brokerRegisterOffCity,
         brokerRegisterOffState :  editableData.brokerRegisterOffState,
         brokerRegisterOffPin :  editableData.brokerRegisterOffPin,
         brokerRegisterPhone :  editableData.brokerRegisterPhone,
         brokerRegisterEmail :  editableData.brokerRegisterEmail,
         brokerRegisterFax :  editableData.brokerRegisterFax,
         brokerCorrespondOffAdd :  editableData.brokerCorrespondOffAdd,
         brokerCorrespondOffCity :   editableData.brokerCorrespondOffCity,
         brokerCorrespondOffState :  editableData.brokerCorrespondOffState,
         brokerCorrespondOffPin :   editableData.brokerCorrespondOffPin,
         brokerCorrespondPhone :  editableData.brokerCorrespondPhone,
         brokerCorrespondEmail :   editableData.brokerCorrespondEmail,
         brokerCorrespondFax :  editableData.brokerCorrespondFax,
         brokerComplianceOfficerName :   editableData.brokerComplianceOfficerName,
         brokerComplianceOfficerPhone :   editableData.brokerComplianceOfficerPhone,
         brokerComplianceOfficerEmail :   editableData.brokerComplianceOfficerEmail,
         brokerCeoName :   editableData.brokerCeoName,
         brokerCeoPhone :   editableData.brokerCeoPhone,
         brokerCeoEmail :  editableData.brokerCeoEmail,
         brokerClearingMemberInfo :  editableData.brokerClearingMemberInfo,
         brokerSebiRegNoNse :  editableData.brokerSebiRegNoNse,
         brokerSebiRegNoBse :  editableData.brokerSebiRegNoBse,
         brokerGrievanceCellInfo :  editableData.brokerGrievanceCellInfo,
         brokerCustomercareNo :  editableData.brokerCustomercareNo,
         brokerCustomercareEmail :  editableData.brokerCustomercareEmail,
         brokerDpTypeNsdl :  editableData.brokerDpTypeNsdl,
         brokerDpStatusNsdl :   editableData.brokerDpStatusNsdl,
         brokerDpNameNsdl :   editableData.brokerDpNameNsdl,
         brokerDpAddressNsdl :   editableData.brokerDpAddressNsdl,
         brokerDpCityNsdl :   editableData.brokerDpCityNsdl,
         brokerDpStateNsdl :   editableData.brokerDpStateNsdl,
         brokerDpPinNsdl :   editableData.brokerDpPinNsdl,
         brokerDpPhoneNsdl :   editableData.brokerDpPhoneNsdl,
         brokerDpEmailNsdl :   editableData.brokerDpEmailNsdl,
         brokerDpWebsiteNsdl :   editableData.brokerDpWebsiteNsdl,
         brokerDpComplianceNameNsdl :   editableData.brokerDpComplianceNameNsdl,
         broker_dpCompliancePhoneNsdl :   editableData.broker_dpCompliancePhoneNsdl,
         brokerDpComplianceEmailNsdl :   editableData.brokerDpComplianceEmailNsdl,
         brokerDpTypeCdsl :   editableData.brokerDpTypeCdsl,
         brokerDpStatusCdsl :  editableData.brokerDpStatusCdsl,
         brokerDpNameCdsl :   editableData.brokerDpNameCdsl,
         brokerDpAddressCdsl :  editableData.brokerDpAddressCdsl,
         brokerDpCityCdsl :   editableData.brokerDpCityCdsl,
         brokerDpStateCdsl :   editableData.brokerDpStateCdsl,
         brokerDpPinCdsl :   editableData.brokerDpPinCdsl,
         brokerDpPhoneCdsl :   editableData.brokerDpPhoneCdsl,
         brokerDpEmailCdsl :   editableData.brokerDpEmailCdsl,
         brokerDpWebsiteCdsl :   editableData.brokerDpWebsiteCdsl,
         brokerDpComplianceNameCdsl :   editableData.brokerDpComplianceNameCdsl,
         brokerDpCompliancePhoneCdsl :   editableData.brokerDpCompliancePhoneCdsl,
         brokerDpComplianceEmailCdsl :   editableData.brokerDpComplianceEmailCdsl,
         brokerLogoUrl :   editableData.brokerLogoUrl,
         brokerCreatedBy :   editableData.brokerCreatedBy,
         brokerUpdatedBy :  editableData.brokerUpdatedBy,
         brokerClearingMemberName :   editableData.brokerClearingMemberName,
         brokerClearingMemberAddr :   editableData.brokerClearingMemberAddr,
         brokerClearingMemberCity :   editableData.brokerClearingMemberCity,
         brokerClearingMemberState :   editableData.brokerClearingMemberState,
         brokerClearingMemberPin :   editableData.brokerClearingMemberPin,
         brokerClearingMemberPhone :   editableData.brokerClearingMemberPhone,
         brokerClearingMemberFax :  editableData.brokerClearingMemberFax,
         brokerClearingMemberEmail :   editableData.brokerClearingMemberEmail,
         brokerClearingMemberWebsite :   editableData.brokerClearingMemberWebsite,
         brokerDPId :  editableData.brokerDPId,
         brokerSebiRegBseDate :   editableData.brokerSebiRegBseDate,
         brokerSebiRegDpDate :   editableData.brokerSebiRegDpDate,
         brokerSebiRegNoDp :  editableData.brokerSebiRegNoDp,
         brokerSebiRegNseDate :   editableData.brokerSebiRegNseDate,
         brokerShortName :  editableData.brokerShortName,
         brokerSebiRegNo :   editableData.brokerSebiRegNo,
         bseCeoEmail :   editableData.bseCeoEmail,
         bseCeoName :  editableData.bseCeoName,
         bseCeoPhone :   editableData.bseCeoPhone,
         nseCeoEmail :   editableData.nseCeoEmail,
         nseCeoName :  editableData.nseCeoName,
         nseCeoPhone :   editableData.nseCeoPhone,
         brokerStarRating :  editableData.brokerStarRating,
         brokerRecommendedRating :  editableData.brokerRecommendedRating,
         registrationNoNsdl :  editableData.registrationNoNsdl,
         registrationDateNsdl :  editableData.registrationDateNsdl,
         registrationNoCdsl :   editableData.registrationNoCdsl,
         registrationDateCdsl :  editableData.registrationDateCdsl,
         currentValue :  editableData.currentValue,
         endValue :  editableData.endValue,
         incrementBy :  editableData.incrementBy,
         seedValue :  editableData.seedValue,
         series :   editableData.series,
         brokerUccCurrentValue :  editableData.brokerUccCurrentValue,
         brokerUccEndValue :  editableData.brokerUccEndValue,
         brokerUccIncrementBy :  editableData.brokerUccIncrementBy,
         brokerUccSeedValue :  editableData.brokerUccSeedValue,
         brokerUccSeries :  editableData.brokerUccSeries,
         brokerUpcomingFlag :  editableData.brokerUpcomingFlag,
         clearingMemberCinNo :  editableData.clearingMemberCinNo,
         clearingMemberSebiRegNo :  editableData.clearingMemberSebiRegNo,
         brokerGrievanceEmailId :  editableData.brokerGrievanceEmailId,
         brokerKycEmailId :  editableData.brokerKycEmailId,
         boId :  editableData.boId,
         brokerCdslEdisFlag :   editableData.brokerCdslEdisFlag,
         brokerStampUrl :  editableData.brokerStampUrl,
         brokerMailHost :  editableData.brokerMailHost,
         brokerMailId :  editableData.brokerMailId,
         brokerMailPassword :  editableData.brokerMailPassword,
         brokerMailPort :  editableData.brokerMailPort,

    };
    console.log(data, "modified data");
      modifyCall(data);
      setEditableData({
    brokerInv19Id: '',
    brokerName : '',
     brokerNseMemberId : '',
     brokerBseMemberId : '',
     brokerNseExchName : '',
     brokerBseExchName : '',
     brokerNseTradingId : '',
     brokerBseTradingId : '',
     brokerWebsiteUrl : '',
     brokerBackofficeName : '',
     brokerBackofficeUrl : '',
     brokerRegisterOffAdd : '',
     brokerRegisterOffCity : '',
     brokerRegisterOffState : '',
     brokerRegisterOffPin : '',
     brokerRegisterPhone : '',
     brokerRegisterEmail : '',
     brokerRegisterFax : '',
     brokerCorrespondOffAdd : '',
     brokerCorrespondOffCity :  '',
     brokerCorrespondOffState : '',
     brokerCorrespondOffPin :  '',
     brokerCorrespondPhone : '',
     brokerCorrespondEmail :  '',
     brokerCorrespondFax : '',
     brokerComplianceOfficerName :  '',
     brokerComplianceOfficerPhone :  '',
     brokerComplianceOfficerEmail :  '',
     brokerCeoName :  '',
     brokerCeoPhone :  '',
     brokerCeoEmail : '',
     brokerClearingMemberInfo : '',
     brokerSebiRegNoNse : '',
     brokerSebiRegNoBse : '',
     brokerGrievanceCellInfo : '',
     brokerCustomercareNo : '',
     brokerCustomercareEmail : '',
     brokerDpTypeNsdl : '',
     brokerDpStatusNsdl :  '',
     brokerDpNameNsdl :  '',
     brokerDpAddressNsdl :  '',
     brokerDpCityNsdl :  '',
     brokerDpStateNsdl :  '',
     brokerDpPinNsdl :  '',
     brokerDpPhoneNsdl :  '',
     brokerDpEmailNsdl :  '',
     brokerDpWebsiteNsdl :  '',
     brokerDpComplianceNameNsdl :  '',
     broker_dpCompliancePhoneNsdl :  '',
     brokerDpComplianceEmailNsdl :  '',
     brokerDpTypeCdsl :  '',
     brokerDpStatusCdsl : '',
     brokerDpNameCdsl :  '',
     brokerDpAddressCdsl : '',
     brokerDpCityCdsl :  '',
     brokerDpStateCdsl :  '',
     brokerDpPinCdsl :  '',
     brokerDpPhoneCdsl :  '',
     brokerDpEmailCdsl :  '',
     brokerDpWebsiteCdsl :  '',
     brokerDpComplianceNameCdsl :  '',
     brokerDpCompliancePhoneCdsl :  '',
     brokerDpComplianceEmailCdsl :  '',
     brokerLogoUrl :  '',
     brokerCreatedBy :  '',
     brokerUpdatedBy : '',
     brokerClearingMemberName :  '',
     brokerClearingMemberAddr :  '',
     brokerClearingMemberCity :  '',
     brokerClearingMemberState :  '',
     brokerClearingMemberPin :  '',
     brokerClearingMemberPhone :  '',
     brokerClearingMemberFax : '',
     brokerClearingMemberEmail :  '',
     brokerClearingMemberWebsite :  '',
     brokerDPId : '',
     brokerSebiRegBseDate :  '',
     brokerSebiRegDpDate :  '',
     brokerSebiRegNoDp : '',
     brokerSebiRegNseDate :  '',
     brokerShortName : '',
     brokerSebiRegNo :  '',
     bseCeoEmail :  '',
     bseCeoName : '',
     bseCeoPhone :  '',
     nseCeoEmail :  '',
     nseCeoName : '',
     nseCeoPhone :  '',
     brokerStarRating : '',
     brokerRecommendedRating : '',
     registrationNoNsdl : '',
     registrationDateNsdl : '',
     registrationNoCdsl :  '',
     registrationDateCdsl : '',
     currentValue : '',
     endValue : '',
     incrementBy : '',
     seedValue : '',
     series :  '',
     brokerUccCurrentValue : '',
     brokerUccEndValue : '',
     brokerUccIncrementBy : '',
     brokerUccSeedValue : '',
     brokerUccSeries : '',
     brokerUpcomingFlag : '',
     clearingMemberCinNo : '',
     clearingMemberSebiRegNo : '',
     brokerGrievanceEmailId : '',
     brokerKycEmailId : '',
     boId : '',
     brokerCdslEdisFlag :  '',
     brokerStampUrl : '',
     brokerMailHost : '',
     brokerMailId : '',
     brokerMailPassword : '',
     brokerMailPort : '',

      });
      setNofication(true);
    
  };


  // const onHandlerKeyUpEntryExpPrice = (e: any) => {
  //   //console.log(e.target.name, e.target.value);

  //   if (editableData.entryPrice !== "" && editableData.targetPrice !== "") {
  //     let targetPrice = parseInt(editableData.targetPrice);
  //     let entryPrice = parseInt(editableData.entryPrice);
  //     let expReturninPer = Math.abs(
  //       ((targetPrice - entryPrice) / entryPrice) * 100
  //     ).toFixed(2);
  //     setEditableData({
  //       ...editableData,
  //       expectedReturnInPercentage: expReturninPer,
  //     });
  //   } else if (
  //     editableData.entryPrice === "" &&
  //     editableData.targetPrice === ""
  //   ) {
  //     setEditableData({
  //       ...editableData,
  //       expectedReturnInPercentage: "",
  //     });
  //   }
  // };



  const modifyCall = (data: any) => {
    dispatch(UserListUseCaseHanlder.editBrokerCallsData(data));
    setOpen(false);
  };

  const addDefaultSrc = (e) => {
    e.target.src="https://invest19.com/notfound.png";
    e.target.onerror = null;
  };

  const removeData = async brokerInv19Id => {
    console.log("ID", brokerInv19Id);
    

    try {

      const response = await axios.delete(`https://gatewayapi.invest19.com/broker/broker/${brokerInv19Id}`);
      console.log(response);
      
    } catch (error) {
      console.log(error);
    }
  };


  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('xl');

  
  // const headers = [
  //   { name: "symbol", field: "symbol", sortable: true },
  //   { name: "company Name", field: "company_Name", sortable: true },
  //   { name: "industry", field: "industry", sortable: true },
  //   { name: "range", field: "range", sortable: true },
  //   { name: "Open Date", field: "openDate", sortable: true },
  //   { name: "Close Date", field: "closeDate", sortable: true },
  //   { name: "Listing Date", field: "ListingDate", sortable: false },
  //   { name: "app. ser. start", field: "application_series_start", sortable: true },
  //   { name: "app. ser. end", field: "application_series_end", sortable: true },
  //   { name: "fin./RHP", field: "", sortable: false },
  //   { name: "status", field: "status", sortable: true },
  //   { name: "Action", field: "", sortable: false },
  // ];

  const datasData = useMemo(() => {
    let computedDatas = datas;
    if (computedDatas) {
      if (search) {
        computedDatas = computedDatas.filter(
          (data: any) =>
          data.symble.toLowerCase().includes(search.toLowerCase()) ||
          data.company_Name.toLowerCase().includes(search.toLowerCase()) 
        );
      }
      setTotalItems(computedDatas.length);
    }

    //Sorting datas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedDatas = computedDatas.sort(
        (a: any, b: any) =>
          reversed *
          a[sorting.field]
            .toString()
            .localeCompare(b[sorting.field], undefined, {
              numeric: true,
            })
      );
    }

    //Current Page slice
    return computedDatas.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [datas, currentPage, search, sorting]);


  const clickHandle = () => {
    window.location.reload();
    console.log('You clicked the button')
}
const history = useHistory();

const onUpload = (url) => {
  setEditableData((oldValue) => {
    return { ...oldValue, brokerLogoUrl: url };
  })
}

  return (
    <div className="CoomonT mainCt">
    <div className="innerprofilesection">
        <Grid className="CoomonT">
          <div className="clapse ">
    <>
      {/* <Search
        onSearch={(value) => {
          setSearch(value);
          setCurrentPage(1);
        }}
      /> */}

<Paper className="tableP broker_t">

<Button onClick={()=> history.push("/Add-new-broker")} color="primary" variant="outlined" >Add New broker</Button>


  
        <Grid container className="listContainer ipolisting">
        {/* <div title="add">
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={handleClickOpen}
                          >
                            
                          add new
                          </IconButton>
                        </div> */}



              {fetchedUserList ? (
                datasData.map((data: any) => (
                  <div id={data.brokerInv19Id} key={data.brokerInv19Id} className="listMain col-sm-6 col-md-4 col-xs-6">
                  <div className="innerC">
                    <div className="thumbnail">
                    {/* <input onClick={handleClick} id={data.brokerInv19Id} checked={data.isEnabled} value={data.isEnabled} type="checkbox" /> */}
                    <Card className="card">
                    <div className="imgBkHeader"> 
                    <div className="actionbtn">
                      <IconButton aria-label="delete" size="small">
                        <DeleteOutlineIcon fontSize="small" 
                        // onClick={() => } 
                        onClick={(event) => { removeData(data.brokerInv19Id); clickHandle();}}
                        />
                      </IconButton>
                      <Tooltip title="Edit">
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => {
                              setOpen(true);
                              setEditaBtnClicked(true);
                              setEditableData(data);
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                    </div>
                    <div className="imgBk"> 
                    <img src={data.brokerLogoUrl} onError={addDefaultSrc} alt={data.brokerName}/></div></div>
                      <div className="caption">
                        <h4>{data.brokerName}</h4>
                        </div>
                      
                    </Card>
                    </div>
                  </div>
                  
                  </div>
                ))
             
              ) : (
              
                  <Loader />
              
              )}
          <div>




{/* 
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={maxWidth}
        className="adbrokerList"
      >
        <DialogTitle id="alert-dialog-title">{"Add new broker?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Ipo/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog> */}





      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={maxWidth}
        className="editDialog"
      >
        <DialogTitle id="alert-dialog-title">
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              <CloseIcon />
            </Button>
          </DialogActions>
        </DialogTitle>

        <DialogContent>
          <Grid container className="addDatasection">
            {editaBtnClicked && editableData ? (
              <form
                style={formContainer}
                onSubmit={handleSubmit}
                id="modifyipo"
              >
              {/* <div className="formW">
                <TextField type="text" label="brokerInv19Id" name="brokerInv19Id"   onChange={handleChange}  value={editableData.brokerInv19Id} />
              </div>
              <div className="formW">
                <TextField type="text" label="brokerName" name="brokerName"  onChange={handleChange}  value={editableData.brokerName}  />
              </div>
              <div className="formW">
                <TextField type="text" label="brokerLogoUrl" name="brokerLogoUrl"  onChange={handleChange} value={editableData.brokerLogoUrl}  />
              </div> */}

{/* <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log(editableData.entryPrice);
              setPublishModal(false);
              setNofication(true);
            }}
          >
            No
          </Button> */}

        <Grid className="profile modal-form">
          
            <>
              <div className="proofileS">
                
              <div className="modifyActionBtn">
                  <Button
                    variant="contained"
                    type="submit"
                    value="submit"
                    // onClick={(event) => { setPublishModal(false); clickHandle();}}
                    
                    onClick={() => {
                      setPublishModal(false);
                    }}
                  >
                    <IconButton aria-label="DeleteOutlineOutlinedIcon">
                    <CheckRoundedIcon/>
                    </IconButton>
                    
                  </Button>

                  <Button
                    variant="contained"
                    onClick={handleClose}
                  >
<IconButton aria-label="DeleteOutlineOutlinedIcon">
                                        <CloseRoundedIcon/>
                    </IconButton>
                    
                  </Button>
                </div>
                <div className="proofileP">
                  <div className="profic"><img src={editableData.brokerLogoUrl} onError={addDefaultSrc} alt={editableData.brokerName} />
                  </div>
                  <div className="name">

                    <span>{editableData.brokerName} </span>
                  </div>
                  {/* <div className="actionB">
                    <IconButton aria-label="DeleteOutlineOutlinedIcon">
                    <DeleteRoundedIcon fontSize="small" />
                    </IconButton>
                  </div> */}
                </div>
              </div>

             


              <div className="Pinfo">
                 <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">Registered Office Details</h1>
                  <div className="formW">
                          <label>Upload Logo</label>
                          <TradeImportComponent upload={onUpload} />
                        </div>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">brokerRegisterOffAdd: </TableCell>
                        <TableCell align="left">
                        <TextField type="text" label="" name="brokerRegisterOffAdd"   onChange={handleChange}  value={editableData.brokerRegisterOffAdd} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerRegisterOffCity: </TableCell>
                        <TableCell align="left">
                        <TextField type="text" label="" name="brokerRegisterOffCity"   onChange={handleChange}  value={editableData.brokerRegisterOffCity} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerRegisterOffState: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerRegisterOffState"   onChange={handleChange}  value={editableData.brokerRegisterOffState} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerRegisterOffPin: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerRegisterOffPin"   onChange={handleChange}  value={editableData.brokerRegisterOffPin} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerRegisterPhone: </TableCell>
                        <TableCell align="left"> <TextField type="text" label="" name="brokerRegisterPhone"   onChange={handleChange}  value={editableData.brokerRegisterPhone} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerRegisterEmail: </TableCell>
                        <TableCell align="left"> <TextField type="text" label="" name="brokerRegisterEmail"   onChange={handleChange}  value={editableData.brokerRegisterEmail} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerRegisterFax: </TableCell>
                        <TableCell align="left"> 
                        <TextField type="text" label="" name="brokerRegisterFax"   onChange={handleChange}  value={editableData.brokerRegisterFax} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </Grid>
                  <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">Correspondence  Office</h1>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">brokerCorrespondOffAdd: </TableCell>
                        <TableCell align="left">
                        <TextField type="text" label="" name="brokerCorrespondOffAdd"   onChange={handleChange}  value={editableData.brokerCorrespondOffAdd} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerCorrespondOffCity: </TableCell>
                        <TableCell align="left">
                        <TextField type="text" label="" name="brokerCorrespondOffCity"   onChange={handleChange}  value={editableData.brokerCorrespondOffCity} /> 
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerCorrespondOffState: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerCorrespondOffState"   onChange={handleChange}  value={editableData.brokerCorrespondOffState} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerCorrespondOffPin: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerCorrespondOffPin"   onChange={handleChange}  value={editableData.brokerCorrespondOffPin} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerCorrespondPhone: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerCorrespondPhone"   onChange={handleChange}  value={editableData.brokerCorrespondPhone} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Website: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerCorrespondEmail"   onChange={handleChange}  value={editableData.brokerCorrespondEmail} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Website: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerCorrespondFax"   onChange={handleChange}  value={editableData.brokerCorrespondFax} /></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </Grid>
                  <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">CEO</h1>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">brokerCeoName: </TableCell>
                        <TableCell align="left">
                        <TextField type="text" label="" name="brokerCeoName"   onChange={handleChange}  value={editableData.brokerCeoName} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerCeoPhone: </TableCell>
                        <TableCell align="left">
                        <TextField type="text" label="" name="brokerCeoPhone"   onChange={handleChange}  value={editableData.brokerCeoPhone} /> 
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Email: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerCeoEmail"   onChange={handleChange}  value={editableData.brokerCeoEmail} /></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </Grid>
                  <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">Clearing Member Information</h1>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">brokerClearingMemberInfo: </TableCell>
                        <TableCell align="left">
                        <TextField type="text" label="" name="brokerClearingMemberInfo"   onChange={handleChange}  value={editableData.brokerClearingMemberInfo} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerClearingMemberName: </TableCell>
                        <TableCell align="left">
                        <TextField type="text" label="" name="brokerClearingMemberName"   onChange={handleChange}  value={editableData.brokerClearingMemberName} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerClearingMemberAddr: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerClearingMemberAddr"   onChange={handleChange}  value={editableData.brokerClearingMemberAddr} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerClearingMemberCity: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerClearingMemberCity"   onChange={handleChange}  value={editableData.brokerClearingMemberCity} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerClearingMemberState: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerClearingMemberState"   onChange={handleChange}  value={editableData.brokerClearingMemberState} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerClearingMemberPin: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerClearingMemberPin"   onChange={handleChange}  value={editableData.brokerClearingMemberPin} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerClearingMemberPhone: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerClearingMemberPhone"   onChange={handleChange}  value={editableData.brokerClearingMemberPhone} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerClearingMemberFax: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerClearingMemberFax"   onChange={handleChange}  value={editableData.brokerClearingMemberFax} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerClearingMemberEmail: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerClearingMemberEmail"   onChange={handleChange}  value={editableData.brokerClearingMemberEmail} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerClearingMemberWebsite: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerClearingMemberWebsite"   onChange={handleChange}  value={editableData.brokerClearingMemberWebsite} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">clearingMemberCinNo: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="clearingMemberCinNo"   onChange={handleChange}  value={editableData.clearingMemberCinNo} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">clearingMemberSebiRegNo: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="clearingMemberSebiRegNo"   onChange={handleChange}  value={editableData.clearingMemberSebiRegNo} /></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </Grid>
                  <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">Member ID</h1>
                  <Table>
                    <TableBody>
                    <TableRow>
                        <TableCell align="left">brokerName: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerName"   onChange={handleChange}  value={editableData.brokerName} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerNseMemberId: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerNseMemberId"   onChange={handleChange}  value={editableData.brokerNseMemberId} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerBseMemberId: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerBseMemberId"   onChange={handleChange}  value={editableData.brokerBseMemberId} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerNseExchName: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerNseExchName"   onChange={handleChange}  value={editableData.brokerNseExchName} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerBseExchName: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerBseExchName"   onChange={handleChange}  value={editableData.brokerBseExchName} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerNseTradingId: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerNseTradingId"   onChange={handleChange}  value={editableData.brokerNseTradingId} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerBseTradingId: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerBseTradingId"   onChange={handleChange}  value={editableData.brokerBseTradingId} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerWebsiteUrl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerWebsiteUrl"   onChange={handleChange}  value={editableData.brokerWebsiteUrl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerBackofficeName: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerBackofficeName"   onChange={handleChange}  value={editableData.brokerBackofficeName} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerBackofficeUrl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerBackofficeUrl"   onChange={handleChange}  value={editableData.brokerBackofficeUrl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerLogoUrl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerLogoUrl"   onChange={handleChange}  value={editableData.brokerLogoUrl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerCreatedBy: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerCreatedBy"   onChange={handleChange}  value={editableData.brokerCreatedBy} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerUpdatedBy: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerUpdatedBy"   onChange={handleChange}  value={editableData.brokerUpdatedBy} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">currentValue: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="currentValue"   onChange={handleChange}  value={editableData.currentValue} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">endValue: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="endValue"   onChange={handleChange}  value={editableData.endValue} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">incrementBy: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="incrementBy"   onChange={handleChange}  value={editableData.incrementBy} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">seedValue: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="seedValue"   onChange={handleChange}  value={editableData.seedValue} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">series: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="series"   onChange={handleChange}  value={editableData.series} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerUccCurrentValue: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerUccCurrentValue"   onChange={handleChange}  value={editableData.brokerUccCurrentValue} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerUccEndValue: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerUccEndValue"   onChange={handleChange}  value={editableData.brokerUccEndValue} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerUccIncrementBy: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerUccIncrementBy"   onChange={handleChange}  value={editableData.brokerUccIncrementBy} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerUccSeedValue: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerUccSeedValue"   onChange={handleChange}  value={editableData.brokerUccSeedValue} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerUccSeries: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerUccSeries"   onChange={handleChange}  value={editableData.brokerUccSeries} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerUpcomingFlag: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerUpcomingFlag"   onChange={handleChange}  value={editableData.brokerUpcomingFlag} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerCdslEdisFlag: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerCdslEdisFlag"   onChange={handleChange}  value={editableData.brokerCdslEdisFlag} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerStampUrl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerStampUrl"   onChange={handleChange}  value={editableData.brokerStampUrl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerMailHost: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerMailHost"   onChange={handleChange}  value={editableData.brokerMailHost} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerMailId: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerMailId"   onChange={handleChange}  value={editableData.brokerMailId} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerMailPassword: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerMailPassword"   onChange={handleChange}  value={editableData.brokerMailPassword} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerMailPort: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerMailPort"   onChange={handleChange}  value={editableData.brokerMailPort} /></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </Grid>
                  <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">NSE Registration Number</h1>
                  <Table>
                    <TableBody>
                    <TableRow>
                        <TableCell align="left">brokerSebiRegNoNse: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerSebiRegNoNse"   onChange={handleChange}  value={editableData.brokerSebiRegNoNse} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerSebiRegNseDate: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerSebiRegNseDate"   onChange={handleChange}  value={editableData.brokerSebiRegNseDate} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerSebiRegDpDate: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerSebiRegDpDate"   onChange={handleChange}  value={editableData.brokerSebiRegDpDate} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerSebiRegNoDp: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerSebiRegNoDp"   onChange={handleChange}  value={editableData.brokerSebiRegNoDp} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerShortName: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerShortName"   onChange={handleChange}  value={editableData.brokerShortName} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerSebiRegNo: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerSebiRegNo"   onChange={handleChange}  value={editableData.brokerSebiRegNo} /></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </Grid>
                  <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">BSE Registration Number</h1>
                  <Table>
                  <TableBody>
                    <TableRow>
                        <TableCell align="left">brokerSebiRegNoBse: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerSebiRegNoBse"   onChange={handleChange}  value={editableData.brokerSebiRegNoBse} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerSebiRegBseDate: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerSebiRegBseDate"   onChange={handleChange}  value={editableData.brokerSebiRegBseDate} /></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </Grid>
                  
                  <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">Member Grievance Cell</h1>
                  <Table>
                    <TableBody>
                    <TableRow>
                        <TableCell align="left">brokerStarRating: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerStarRating"   onChange={handleChange}  value={editableData.brokerStarRating} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerRecommendedRating: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerRecommendedRating"   onChange={handleChange}  value={editableData.brokerRecommendedRating} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerGrievanceEmailId: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerGrievanceEmailId"   onChange={handleChange}  value={editableData.brokerGrievanceEmailId} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerKycEmailId: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerKycEmailId"   onChange={handleChange}  value={editableData.brokerKycEmailId} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">boId: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="boId"   onChange={handleChange}  value={editableData.boId} /></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </Grid>
                  <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">Exchange Grievance Cell</h1>
                  <h5 className="hd_PP">NSE</h5>
                  <Table>
                    <TableBody>
                    <TableRow>
                        <TableCell align="left">nseCeoEmail: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="nseCeoEmail"   onChange={handleChange}  value={editableData.nseCeoEmail} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">nseCeoName: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="nseCeoName"   onChange={handleChange}  value={editableData.nseCeoName} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">nseCeoPhone: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="nseCeoPhone"   onChange={handleChange}  value={editableData.nseCeoPhone} /></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <h5 className="hd_PP">BSE</h5>
                  <Table>
                    <TableBody>
                    <TableRow>
                        <TableCell align="left">bseCeoEmail: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="bseCeoEmail"   onChange={handleChange}  value={editableData.bseCeoEmail} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">bseCeoName: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="bseCeoName"   onChange={handleChange}  value={editableData.bseCeoName} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">bseCeoPhone: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="bseCeoPhone"   onChange={handleChange}  value={editableData.bseCeoPhone} /></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <h5 className="hd_PP">MSEI</h5>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left"> </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </Grid>
                  <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">DP(NSDL)</h1>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left"> </TableCell>
                        <TableCell align="left">
                        
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </Grid>
                  <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">DP Details</h1>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </Grid>
                  <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">NSDL Registration Number</h1>
                  <Table>
                    <TableBody>
                    <TableRow>
                        <TableCell align="left">brokerDpTypeNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpTypeNsdl"   onChange={handleChange}  value={editableData.brokerDpTypeNsdl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">registrationNoNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="registrationNoNsdl"   onChange={handleChange}  value={editableData.registrationNoNsdl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">registrationDateNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="registrationDateNsdl"   onChange={handleChange}  value={editableData.registrationDateNsdl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpStatusNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpStatusNsdl"   onChange={handleChange}  value={editableData.brokerDpStatusNsdl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpNameNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpNameNsdl"   onChange={handleChange}  value={editableData.brokerDpNameNsdl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpAddressNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpAddressNsdl"   onChange={handleChange}  value={editableData.brokerDpAddressNsdl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpCityNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpCityNsdl"   onChange={handleChange}  value={editableData.brokerDpCityNsdl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpStateNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpStateNsdl"   onChange={handleChange}  value={editableData.brokerDpStateNsdl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpPinNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpPinNsdl"   onChange={handleChange}  value={editableData.brokerDpPinNsdl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpPhoneNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpPhoneNsdl"   onChange={handleChange}  value={editableData.brokerDpPhoneNsdl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpEmailNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpEmailNsdl"   onChange={handleChange}  value={editableData.brokerDpEmailNsdl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpWebsiteNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpWebsiteNsdl"   onChange={handleChange}  value={editableData.brokerDpWebsiteNsdl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpComplianceNameNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpComplianceNameNsdl"   onChange={handleChange}  value={editableData.brokerDpComplianceNameNsdl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">broker_dpCompliancePhoneNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="broker_dpCompliancePhoneNsdl"   onChange={handleChange}  value={editableData.broker_dpCompliancePhoneNsdl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpComplianceEmailNsdl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpComplianceEmailNsdl"   onChange={handleChange}  value={editableData.brokerDpComplianceEmailNsdl} /></TableCell>
                      </TableRow>
                     
                    </TableBody>
                  </Table>
                  </Grid>
                  <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">COMPLIANCE OFFICER (DP)</h1>
                  <Table>
                    <TableBody>
                    <TableRow>
                        <TableCell align="left">brokerComplianceOfficerName: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerComplianceOfficerName"   onChange={handleChange}  value={editableData.brokerComplianceOfficerName} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerComplianceOfficerPhone: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerComplianceOfficerPhone"   onChange={handleChange}  value={editableData.brokerComplianceOfficerPhone} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerComplianceOfficerEmail: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerComplianceOfficerEmail"   onChange={handleChange}  value={editableData.brokerComplianceOfficerEmail} /></TableCell>
                      </TableRow>
                      
                    </TableBody>
                  </Table>
                  </Grid>
                  <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">DP(CSDL)</h1>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left"> </TableCell>
                        <TableCell align="left">
                        
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </Grid>
                  
                  <Grid item xs={12} className="tbl">
                  <h1 className="hd_P">CDSL Registration Number</h1>
                  <Table>
                    <TableBody>
                    <TableRow>
                        <TableCell align="left">brokerDPId: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDPId"   onChange={handleChange}  value={editableData.brokerDPId} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">registrationNoCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="registrationNoCdsl"   onChange={handleChange}  value={editableData.registrationNoCdsl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">registrationDateCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="registrationDateCdsl"   onChange={handleChange}  value={editableData.registrationDateCdsl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpTypeCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpTypeCdsl"   onChange={handleChange}  value={editableData.brokerDpTypeCdsl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpStatusCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpStatusCdsl"   onChange={handleChange}  value={editableData.brokerDpStatusCdsl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpNameCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpNameCdsl"   onChange={handleChange}  value={editableData.brokerDpNameCdsl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpAddressCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpAddressCdsl"   onChange={handleChange}  value={editableData.brokerDpAddressCdsl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpCityCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpCityCdsl"   onChange={handleChange}  value={editableData.brokerDpCityCdsl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpStateCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpStateCdsl"   onChange={handleChange}  value={editableData.brokerDpStateCdsl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpPinCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpPinCdsl"   onChange={handleChange}  value={editableData.brokerDpPinCdsl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpPhoneCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpPhoneCdsl"   onChange={handleChange}  value={editableData.brokerDpPhoneCdsl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpEmailCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpEmailCdsl"   onChange={handleChange}  value={editableData.brokerDpEmailCdsl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpWebsiteCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpWebsiteCdsl"   onChange={handleChange}  value={editableData.brokerDpWebsiteCdsl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpComplianceNameCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpComplianceNameCdsl"   onChange={handleChange}  value={editableData.brokerDpComplianceNameCdsl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpCompliancePhoneCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpCompliancePhoneCdsl"   onChange={handleChange}  value={editableData.brokerDpCompliancePhoneCdsl} /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">brokerDpComplianceEmailCdsl: </TableCell>
                        <TableCell align="left"><TextField type="text" label="" name="brokerDpComplianceEmailCdsl"   onChange={handleChange}  value={editableData.brokerDpComplianceEmailCdsl} /></TableCell>
                      </TableRow>
                      
                    </TableBody>
                  </Table>
                  </Grid>
              </div>
            </>

        </Grid>

        </form>
            ) : null}

          </Grid>
        </DialogContent>
      </Dialog>
    </div>
        </Grid>
      </Paper>
      <div className="align-right">
        <Pagination
          total={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          // currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
    </div>
          </Grid>
          </div>
          </div>
  );
};
const formContainer = {
  display: "flex",
  flexFlow: "row wrap",
};

export default AgentMaster;


function setTodos(arg0: (prevState: any) => any) {
  throw new Error("Function not implemented.");
}

