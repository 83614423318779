import {
  FETCH_CATEGORY_LIST_DATA,
  FETCH_CATEGORY_LIST_DATA_SUCCESS,
  FETCH_CATEGORY_LIST_DATA_ERROR,
  CATEGORY_CHANGE,
  CATEGORY_CHANGE_SUCCESS,
  CATEGORY_CHANGE_FAILURE,
} from "../actionTypes";

const istate = {
  fetchingCategoryList: false,
  fetchedCategoryList: false,
  fetchingCategoryOnChage: false,
  fetchedCategoryOnChage: false,
  categories: [],
  onChangeCategory: {},
};

export default function userCategoryReducer(state = istate, action) {
  switch (action.type) {
    case FETCH_CATEGORY_LIST_DATA: {
      return {
        ...state,
        fetchingCategoryList: true,
        fetchedCategoryList: false,
      };
    }
    case FETCH_CATEGORY_LIST_DATA_SUCCESS: {
      return {
        ...state,
        //columns: Object.keys(action.payload.columns[0]),
        fetchingCategoryList: false,
        fetchedCategoryList: true,
        categories: action.payload,
      };
    }

    case CATEGORY_CHANGE: {
      return {
        ...state,
        //columns: Object.keys(action.payload.columns[0]),
        fetchingCategoryOnChage: true,
        fetchedCategoryOnChage: false,
        data: action.payload,
      };
    }
    case CATEGORY_CHANGE_SUCCESS: {
      return {
        ...state,
        //columns: Object.keys(action.payload.columns[0]),
        fetchingCategoryOnChage: false,
        fetchedCategoryOnChage: true,
        onChangeCategory: action.payload,
      };
    }
    case CATEGORY_CHANGE_FAILURE: {
      return {
        ...state,
        fetchedCategoryOnChage: false,
        categoryOnchangeError: action.payload,
      };
    }

    case FETCH_CATEGORY_LIST_DATA_ERROR: {
      return {
        ...state,
        fetchedCategoryList: false,
        categoryListError: action.payload,
      };
    }
    default:
      return state;
  }
}
