import React from "react";
import { Route, Switch } from "react-router-dom";
import LogInComponent from "./component/Auth/LoginComponent";
// import SignupComponenrt from "./component/Auth/SignupComponenrt";
import LogOutComponent from "./component/Auth/LogOutComponent";


import DashboardComponent from "./component/User/Dashboard/DashboardComponent";
import GuestUserList from "./component/User/Dashboard/GuestUserList";
import Ipo from "./component/User/Dashboard/ipo";
import ipoUser from "./component/Ipo/ipo";
import revenueModalOne from "./component/revenue/revenueModalOne";
import brokerListComponent from "./component/User/Dashboard/brokerListComponent";
import ApplicationList from "./component/Application/ApplicationList";
import { PrivateRoute } from "./protected.route";
import NotFound from "./component/Utils/NotFound";



const routes = (
  <Switch>
    <Route exact path="/login" component={LogInComponent}></Route>
    {/* <Route exact path="/signup" component={SignupComponenrt}></Route>  */}
    {/* {/* <PrivateRoute exact path="/" component={AddCallComponet}></PrivateRoute> */}
    <PrivateRoute
      exact
      path="/"
      component={DashboardComponent}
    ></PrivateRoute>
        <PrivateRoute
      exact
      path="/GuestUserList"
      component={GuestUserList}
    ></PrivateRoute>
        <Route
      exact
      path="/Add-new-broker"
      component={Ipo}
    ></Route>
    <Route
      exact
      path="/brokerList"
      component={brokerListComponent}
    ></Route>
    <PrivateRoute exact path="/Partner" component={ipoUser}></PrivateRoute>
    <PrivateRoute exact path="/revenueModalOne" component={revenueModalOne}></PrivateRoute>
    <PrivateRoute exact path="/ApplicationList" component={ApplicationList}></PrivateRoute>
    <Route exact path="/logout" component={LogOutComponent} />
    <Route exact path="*" component={NotFound} />
  </Switch>
);

export default routes;
