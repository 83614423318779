import React from "react";
import { withRouter } from "react-router-dom";

const FooterComponent: React.FC = (props: any) => {
  if (props.location.pathname === "/login") {
    return <> </>;
  }
  return (
    <div className="footer">
      All Rights Reserved. © Copyright 2020 invest19.com
    </div>
  );
};

export default withRouter(FooterComponent);
