import React from 'react';
import axios from 'axios';
// import { Form } from 'antd';
// import { List, Card, Form } from 'antd';
import { Grid, Button } from "@material-ui/core/";
import Alert from '@material-ui/lab/Alert';
// const FormItem = Form.Item;
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import { FormControl, Tooltip } from "@material-ui/core/";
import InputLabel from "@material-ui/core/InputLabel";
import { Select } from "@material-ui/core/";
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TradeImportComponent from "../Dashboard/AddFile";
import Axios from 'axios';






const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class Ipo extends React.Component<{},any>{
// export default class ipo extends React.Component {

  custom_file_upload_url = `https://gatewayapi-uat.invest19.com/api/ipo/document/upload`;
  canvas: any;

  constructor(props) {
    super(props)
    this.canvas = React.createRef()
    this.state = {
      brokerName : '',
       brokerNseMemberId : '',
       brokerBseMemberId : '',
       brokerNseExchName : '',
       brokerBseExchName : '',
       brokerNseTradingId : '',
       brokerBseTradingId : '',
       brokerWebsiteUrl : '',
       brokerBackofficeName : '',
       brokerBackofficeUrl : '',
       brokerRegisterOffAdd : '',
       brokerRegisterOffCity : '',
       brokerRegisterOffState : '',
       brokerRegisterOffPin : '',
       brokerRegisterPhone : '',
       brokerRegisterEmail : '',
       brokerRegisterFax : '',
       brokerCorrespondOffAdd : '',
       brokerCorrespondOffCity :  '',
       brokerCorrespondOffState : '',
       brokerCorrespondOffPin :  '',
       brokerCorrespondPhone : '',
       brokerCorrespondEmail :  '',
       brokerCorrespondFax : '',
       brokerComplianceOfficerName :  '',
       brokerComplianceOfficerPhone :  '',
       brokerComplianceOfficerEmail :  '',
       brokerCeoName :  '',
       brokerCeoPhone :  '',
       brokerCeoEmail : '',
       brokerClearingMemberInfo : '',
       brokerSebiRegNoNse : '',
       brokerSebiRegNoBse : '',
       brokerGrievanceCellInfo : '',
       brokerCustomercareNo : '',
       brokerCustomercareEmail : '',
       brokerDpTypeNsdl : '',
       brokerDpStatusNsdl :  '',
       brokerDpNameNsdl :  '',
       brokerDpAddressNsdl :  '',
       brokerDpCityNsdl :  '',
       brokerDpStateNsdl :  '',
       brokerDpPinNsdl :  '',
       brokerDpPhoneNsdl :  '',
       brokerDpEmailNsdl :  '',
       brokerDpWebsiteNsdl :  '',
       brokerDpComplianceNameNsdl :  '',
       broker_dpCompliancePhoneNsdl :  '',
       brokerDpComplianceEmailNsdl :  '',
       brokerDpTypeCdsl :  '',
       brokerDpStatusCdsl : '',
       brokerDpNameCdsl :  '',
       brokerDpAddressCdsl : '',
       brokerDpCityCdsl :  '',
       brokerDpStateCdsl :  '',
       brokerDpPinCdsl :  '',
       brokerDpPhoneCdsl :  '',
       brokerDpEmailCdsl :  '',
       brokerDpWebsiteCdsl :  '',
       brokerDpComplianceNameCdsl :  '',
       brokerDpCompliancePhoneCdsl :  '',
       brokerDpComplianceEmailCdsl :  '',
       brokerLogoUrl :  '',
       brokerCreatedBy :  '',
       brokerUpdatedBy : '',
       brokerClearingMemberName :  '',
       brokerClearingMemberAddr :  '',
       brokerClearingMemberCity :  '',
       brokerClearingMemberState :  '',
       brokerClearingMemberPin :  '',
       brokerClearingMemberPhone :  '',
       brokerClearingMemberFax : '',
       brokerClearingMemberEmail :  '',
       brokerClearingMemberWebsite :  '',
       brokerDPId : '',
       brokerSebiRegBseDate :  '',
       brokerSebiRegDpDate :  '',
       brokerSebiRegNoDp : '',
       brokerSebiRegNseDate :  '',
       brokerShortName : '',
       brokerSebiRegNo :  '',
       bseCeoEmail :  '',
       bseCeoName : '',
       bseCeoPhone :  '',
       nseCeoEmail :  '',
       nseCeoName : '',
       nseCeoPhone :  '',
       brokerStarRating : '',
       brokerRecommendedRating : '',
       registrationNoNsdl : '',
       registrationDateNsdl : '',
       registrationNoCdsl :  '',
       registrationDateCdsl : '',
       currentValue : '',
       endValue : '',
       incrementBy : '',
       seedValue : '',
       series :  '',
       brokerUccCurrentValue : '',
       brokerUccEndValue : '',
       brokerUccIncrementBy : '',
       brokerUccSeedValue : '',
       brokerUccSeries : '',
       brokerUpcomingFlag : '',
       clearingMemberCinNo : '',
       clearingMemberSebiRegNo : '',
       brokerGrievanceEmailId : '',
       brokerKycEmailId : '',
       boId : '',
       brokerCdslEdisFlag :  '',
       brokerStampUrl : '',
       brokerMailHost : '',
       brokerMailId : '',
       brokerMailPassword : '',
       brokerMailPort : ''
    // usergroup: '',
    // emailid: '',
    // mobile: '',
    // title: '',

}
}
  componentDidUpdate(){
    setTimeout(() => this.setState({errorMessage:''}), 40000);
    setTimeout(() => this.setState({sucessMessage:''}), 40000);
  }





    // Image Preview Handler
    handleImagePreview = (e) => {
      let image_as_base64 = URL.createObjectURL(e.target.files[0])
      let image_as_files = e.target.files[0];

      this.setState({
          image_preview: image_as_base64,
          image_file: image_as_files,
      })
  }

  // Image/File Submit Handler
  handleSubmitFileUpload= () => {

      if (this.state.image_file !== null){

          let formData = new FormData();
          formData.append('ipoName', 'tata');
          formData.append('document', this.state.image_file);

          axios.post(
              this.custom_file_upload_url,
              formData,
              {
                  headers: {
                      // "Authorization": "YOUR_API_AUTHORIZATION_KEY_SHOULD_GOES_HERE_IF_HAVE",
                      "Content-type": "multipart/form-data",
                  },                    
              }
          )
          .then(res => {
              console.log(`Success` + res.data.response);
              var json= JSON.parse(JSON.stringify(res.data.response));
              console.log(json);
              console.log(json.url);
              console.log(this.canvas.current.value);
              // this.canvas.current.value=json.url;
              // console.log(this.canvas.current.value);
              return json.url;
          })
          .catch(err => {
              console.log(err);
          })
      }
  }



  uploadDocument = (e) => {
    console.log(e); 
  }
handlebrokerName  = event => {this.setState({ brokerName: event.target.value })}
handlebrokerNseMemberId  = event => {this.setState({ brokerNseMemberId: event.target.value })}
handlebrokerBseMemberId  = event => {this.setState({ brokerBseMemberId: event.target.value })}
handlebrokerNseExchName  = event => {this.setState({ brokerNseExchName: event.target.value })}
handlebrokerBseExchName  = event => {this.setState({ brokerBseExchName: event.target.value })}
handlebrokerNseTradingId  = event => {this.setState({ brokerNseTradingId: event.target.value })}
handlebrokerBseTradingId  = event => {this.setState({ brokerBseTradingId: event.target.value })}
handlebrokerWebsiteUrl  = event => {this.setState({ brokerWebsiteUrl: event.target.value })}
handlebrokerBackofficeName  = event => {this.setState({ brokerBackofficeName: event.target.value })}
handlebrokerBackofficeUrl  = event => {this.setState({ brokerBackofficeUrl: event.target.value })}
handlebrokerRegisterOffAdd  = event => {this.setState({ brokerRegisterOffAdd: event.target.value })}
handlebrokerRegisterOffCity  = event => {this.setState({ brokerRegisterOffCity: event.target.value })}
handlebrokerRegisterOffState  = event => {this.setState({ brokerRegisterOffState: event.target.value })}
handlebrokerRegisterOffPin  = event => {this.setState({ brokerRegisterOffPin: event.target.value })}
handlebrokerRegisterPhone  = event => {this.setState({ brokerRegisterPhone: event.target.value })}
handlebrokerRegisterEmail  = event => {this.setState({ brokerRegisterEmail: event.target.value })}
handlebrokerRegisterFax  = event => {this.setState({ brokerRegisterFax: event.target.value })}
handlebrokerCorrespondOffAdd  = event => {this.setState({ brokerCorrespondOffAdd: event.target.value })}
handlebrokerCorrespondOffCity =  event => {this.setState({ brokerCorrespondOffCity: event.target.value })}
handlebrokerCorrespondOffState  = event => {this.setState({ brokerCorrespondOffState: event.target.value })}
handlebrokerCorrespondOffPin =   event => {this.setState({ brokerCorrespondOffPin: event.target.value })}
handlebrokerCorrespondPhone  = event => {this.setState({ brokerCorrespondPhone: event.target.value })}
handlebrokerCorrespondEmail =   event => {this.setState({ brokerCorrespondEmail: event.target.value })}
handlebrokerCorrespondFax  = event => {this.setState({ brokerCorrespondFax: event.target.value })}
handlebrokerComplianceOfficerName =   event => {this.setState({ brokerComplianceOfficerName: event.target.value })}
handlebrokerComplianceOfficerPhone =   event => {this.setState({ brokerComplianceOfficerPhone: event.target.value })}
handlebrokerComplianceOfficerEmail =   event => {this.setState({ brokerComplianceOfficerEmail: event.target.value })}
handlebrokerCeoName =   event => {this.setState({ brokerCeoName: event.target.value })}
handlebrokerCeoPhone =   event => {this.setState({ brokerCeoPhone: event.target.value })}
handlebrokerCeoEmail  = event => {this.setState({ brokerCeoEmail: event.target.value })}
handlebrokerClearingMemberInfo  = event => {this.setState({ brokerClearingMemberInfo: event.target.value })}
handlebrokerSebiRegNoNse  = event => {this.setState({ brokerSebiRegNoNse: event.target.value })}
handlebrokerSebiRegNoBse  = event => {this.setState({ brokerSebiRegNoBse: event.target.value })}
handlebrokerGrievanceCellInfo  = event => {this.setState({ brokerGrievanceCellInfo: event.target.value })}
handlebrokerCustomercareNo  = event => {this.setState({ brokerCustomercareNo: event.target.value })}
handlebrokerCustomercareEmail  = event => {this.setState({ brokerCustomercareEmail: event.target.value })}
handlebrokerDpTypeNsdl  = event => {this.setState({ brokerDpTypeNsdl: event.target.value })}
handlebrokerDpStatusNsdl =   event => {this.setState({ brokerDpStatusNsdl: event.target.value })}
handlebrokerDpNameNsdl =   event => {this.setState({ brokerDpNameNsdl: event.target.value })}
handlebrokerDpAddressNsdl =  event => {this.setState({ brokerDpAddressNsdl: event.target.value })}
handlebrokerDpCityNsdl =  event => {this.setState({ brokerDpCityNsdl: event.target.value })}
handlebrokerDpStateNsdl =  event => {this.setState({ brokerDpStateNsdl: event.target.value })}
handlebrokerDpPinNsdl =  event => {this.setState({ brokerDpPinNsdl: event.target.value })}
handlebrokerDpPhoneNsdl =  event => {this.setState({ brokerDpPhoneNsdl: event.target.value })}
handlebrokerDpEmailNsdl =  event => {this.setState({ brokerDpEmailNsdl: event.target.value })}
handlebrokerDpWebsiteNsdl =  event => {this.setState({ brokerDpWebsiteNsdl: event.target.value })}
handlebrokerDpComplianceNameNsdl =  event => {this.setState({ brokerDpComplianceNameNsdl: event.target.value })}
handlebroker_dpCompliancePhoneNsdl =  event => {this.setState({ broker_dpCompliancePhoneNsdl: event.target.value })}
handlebrokerDpComplianceEmailNsdl =   event => {this.setState({ brokerDpComplianceEmailNsdl: event.target.value })}
handlebrokerDpTypeCdsl =   event => {this.setState({ brokerDpTypeCdsl: event.target.value })}
handlebrokerDpStatusCdsl  = event => {this.setState({ brokerDpStatusCdsl: event.target.value })}
handlebrokerDpNameCdsl =   event => {this.setState({ brokerDpNameCdsl: event.target.value })}
handlebrokerDpAddressCdsl  = event => {this.setState({ brokerDpAddressCdsl: event.target.value })}
handlebrokerDpCityCdsl =   event => {this.setState({ brokerDpCityCdsl: event.target.value })}
handlebrokerDpStateCdsl =  event => {this.setState({ brokerDpStateCdsl: event.target.value })}
handlebrokerDpPinCdsl =   event => {this.setState({ brokerDpPinCdsl: event.target.value })}
handlebrokerDpPhoneCdsl =  event => {this.setState({ brokerDpPhoneCdsl: event.target.value })}
handlebrokerDpEmailCdsl =   event => {this.setState({ brokerDpEmailCdsl: event.target.value })}
handlebrokerDpWebsiteCdsl =  event => {this.setState({ brokerDpWebsiteCdsl: event.target.value })}
handlebrokerDpComplianceNameCdsl =   event => {this.setState({ brokerDpComplianceNameCdsl: event.target.value })}
handlebrokerDpCompliancePhoneCdsl =   event => {this.setState({ brokerDpCompliancePhoneCdsl: event.target.value })}
handlebrokerDpComplianceEmailCdsl =   event => {this.setState({ brokerDpComplianceEmailCdsl: event.target.value })}
handlebrokerLogoUrl =   event => {this.setState({ brokerLogoUrl: event.target.value })}
handlebrokerCreatedBy =  event => {this.setState({ brokerCreatedBy: event.target.value })}
handlebrokerUpdatedBy  = event => {this.setState({ brokerUpdatedBy: event.target.value })}
handlebrokerClearingMemberName =  event => {this.setState({ brokerClearingMemberName: event.target.value })}
handlebrokerClearingMemberAddr =  event => {this.setState({ brokerClearingMemberAddr: event.target.value })}
handlebrokerClearingMemberCity =  event => {this.setState({ brokerClearingMemberCity: event.target.value })}
handlebrokerClearingMemberState =  event => {this.setState({ brokerClearingMemberState: event.target.value })}
handlebrokerClearingMemberPin =  event => {this.setState({ brokerClearingMemberPin: event.target.value })}
handlebrokerClearingMemberPhone =  event => {this.setState({ brokerClearingMemberPhone: event.target.value })}
handlebrokerClearingMemberFax  = event => {this.setState({ brokerClearingMemberFax: event.target.value })}
handlebrokerClearingMemberEmail =   event => {this.setState({ brokerClearingMemberEmail: event.target.value })}
handlebrokerClearingMemberWebsite = event => {this.setState({ brokerClearingMemberWebsite: event.target.value })}
handlebrokerDPId  = event => {this.setState({ brokerDPId: event.target.value })}
handlebrokerSebiRegBseDate =   event => {this.setState({ brokerSebiRegBseDate: event.target.value })}
handlebrokerSebiRegDpDate =   event => {this.setState({ brokerSebiRegDpDate: event.target.value })}
handlebrokerSebiRegNoDp  = event => {this.setState({ brokerSebiRegNoDp: event.target.value })}
handlebrokerSebiRegNseDate =  event => {this.setState({ brokerSebiRegNseDate: event.target.value })}
handlebrokerShortName  = event => {this.setState({ brokerShortName: event.target.value })}
handlebrokerSebiRegNo =   event => {this.setState({ brokerSebiRegNo: event.target.value })}
handlebseCeoEmail =   event => {this.setState({ bseCeoEmail: event.target.value })}
handlebseCeoName  = event => {this.setState({ bseCeoName: event.target.value })}
handlebseCeoPhone =   event => {this.setState({ bseCeoPhone: event.target.value })}
handlenseCeoEmail =   event => {this.setState({ nseCeoEmail: event.target.value })}
handlenseCeoName  = event => {this.setState({ nseCeoName: event.target.value })}
handlenseCeoPhone =   event => {this.setState({ nseCeoPhone: event.target.value })}
handlebrokerStarRating  = event => {this.setState({ brokerStarRating: event.target.value })}
handlebrokerRecommendedRating  = event => {this.setState({ brokerRecommendedRating: event.target.value })}
handleregistrationNoNsdl  = event => {this.setState({ registrationNoNsdl: event.target.value })}
handleregistrationDateNsdl  = event => {this.setState({ registrationDateNsdl: event.target.value })}
handleregistrationNoCdsl =   event => {this.setState({ registrationNoCdsl: event.target.value })}
handleregistrationDateCdsl  = event => {this.setState({ registrationDateCdsl: event.target.value })}
handlecurrentValue  = event => {this.setState({ currentValue: event.target.value })}
handleendValue  = event => {this.setState({ endValue: event.target.value })}
handleincrementBy  = event => {this.setState({ incrementBy: event.target.value })}
handleseedValue  = event => {this.setState({ seedValue: event.target.value })}
handleseries  = event => {this.setState({ series: event.target.value })}
handlebrokerUccCurrentValue  = event => {this.setState({ brokerUccCurrentValue: event.target.value })}
handlebrokerUccEndValue  = event => {this.setState({ brokerUccEndValue: event.target.value })}
handlebrokerUccIncrementBy  = event => {this.setState({ brokerUccIncrementBy: event.target.value })}
handlebrokerUccSeedValue  = event => {this.setState({ brokerUccSeedValue: event.target.value })}
handlebrokerUccSeries  = event => {this.setState({ brokerUccSeries: event.target.value })}
handlebrokerUpcomingFlag  = event => {this.setState({ brokerUpcomingFlag: event.target.value })}
handleclearingMemberCinNo  = event => {this.setState({ clearingMemberCinNo: event.target.value })}
handleclearingMemberSebiRegNo  = event => {this.setState({ clearingMemberSebiRegNo: event.target.value })}
handlebrokerGrievanceEmailId  = event => {this.setState({ brokerGrievanceEmailId: event.target.value })}
handlebrokerKycEmailId  = event => {this.setState({ brokerKycEmailId: event.target.value })}
handleboId  = event => {this.setState({ boId: event.target.value })}
handlebrokerCdslEdisFlag =  event => {this.setState({ brokerCdslEdisFlag: event.target.value })}
handlebrokerStampUrl  = event => {this.setState({ brokerStampUrl: event.target.value })}
handlebrokerMailHost  = event => {this.setState({ brokerMailHost: event.target.value })}
handlebrokerMailId  = event => {this.setState({ brokerMailId: event.target.value })}
handlebrokerMailPassword  = event => {this.setState({ brokerMailPassword: event.target.value })}
handlebrokerMailPort  = event => {this.setState({ brokerMailPort: event.target.value })}
   
  handleSubmit = event => {
    event.preventDefault();
    event.target.reset()

    // const openDate = {userid: this.state.openDate};
    // const closeDate = {fullname: this.state.closeDate};
    // const listingDate = {usergroup: this.state.listingDate};


    axios.post('https://gatewayapi-uat.invest19.com/broker/add/broker',
      {
        brokerName :  this.state.brokerName,
        brokerNseMemberId :  this.state.brokerNseMemberId,
        brokerBseMemberId :  this.state.brokerBseMemberId,
        brokerNseExchName :  this.state.brokerNseExchName,
        brokerBseExchName :  this.state.brokerBseExchName,
        brokerNseTradingId :  this.state.brokerNseTradingId,
        brokerBseTradingId :  this.state.brokerBseTradingId,
        brokerWebsiteUrl :  this.state.brokerWebsiteUrl,
        brokerBackofficeName :  this.state.brokerBackofficeName,
        brokerBackofficeUrl :  this.state.brokerBackofficeUrl,
        brokerRegisterOffAdd :  this.state.brokerRegisterOffAdd,
        brokerRegisterOffCity :  this.state.brokerRegisterOffCity,
        brokerRegisterOffState :  this.state.brokerRegisterOffState,
        brokerRegisterOffPin :  this.state.brokerRegisterOffPin,
        brokerRegisterPhone :  this.state.brokerRegisterPhone,
        brokerRegisterEmail :  this.state.brokerRegisterEmail,
        brokerRegisterFax :  this.state.brokerRegisterFax,
        brokerCorrespondOffAdd :  this.state.brokerCorrespondOffAdd,
        brokerCorrespondOffCity :   this.state.brokerCorrespondOffCity,
        brokerCorrespondOffState :  this.state.brokerCorrespondOffState,
        brokerCorrespondOffPin :   this.state.brokerCorrespondOffPin,
        brokerCorrespondPhone :  this.state.brokerCorrespondPhone,
        brokerCorrespondEmail :   this.state.brokerCorrespondEmail,
        brokerCorrespondFax :  this.state.brokerCorrespondFax,
        brokerComplianceOfficerName :   this.state.brokerComplianceOfficerName,
        brokerComplianceOfficerPhone :   this.state.brokerComplianceOfficerPhone,
        brokerComplianceOfficerEmail :   this.state.brokerComplianceOfficerEmail,
        brokerCeoName :   this.state.brokerCeoName,
        brokerCeoPhone :   this.state.brokerCeoPhone,
        brokerCeoEmail :  this.state.brokerCeoEmail,
        brokerClearingMemberInfo :  this.state.brokerClearingMemberInfo,
        brokerSebiRegNoNse :  this.state.brokerSebiRegNoNse,
        brokerSebiRegNoBse :  this.state.brokerSebiRegNoBse,
        brokerGrievanceCellInfo :  this.state.brokerGrievanceCellInfo,
        brokerCustomercareNo :  this.state.brokerCustomercareNo,
        brokerCustomercareEmail :  this.state.brokerCustomercareEmail,
        brokerDpTypeNsdl :  this.state.brokerDpTypeNsdl,
        brokerDpStatusNsdl :   this.state.brokerDpStatusNsdl,
        brokerDpNameNsdl :   this.state.brokerDpNameNsdl,
        brokerDpAddressNsdl :   this.state.brokerDpAddressNsdl,
        brokerDpCityNsdl :   this.state.brokerDpCityNsdl,
        brokerDpStateNsdl :   this.state.brokerDpStateNsdl,
        brokerDpPinNsdl :   this.state.brokerDpPinNsdl,
        brokerDpPhoneNsdl :   this.state.brokerDpPhoneNsdl,
        brokerDpEmailNsdl :   this.state.brokerDpEmailNsdl,
        brokerDpWebsiteNsdl :   this.state.brokerDpWebsiteNsdl,
        brokerDpComplianceNameNsdl :   this.state.brokerDpComplianceNameNsdl,
        broker_dpCompliancePhoneNsdl :   this.state.broker_dpCompliancePhoneNsdl,
        brokerDpComplianceEmailNsdl :   this.state.brokerDpComplianceEmailNsdl,
        brokerDpTypeCdsl :   this.state.brokerDpTypeCdsl,
        brokerDpStatusCdsl :  this.state.brokerDpStatusCdsl,
        brokerDpNameCdsl :   this.state.brokerDpNameCdsl,
        brokerDpAddressCdsl :  this.state.brokerDpAddressCdsl,
        brokerDpCityCdsl :   this.state.brokerDpCityCdsl,
        brokerDpStateCdsl :   this.state.brokerDpStateCdsl,
        brokerDpPinCdsl :   this.state.brokerDpPinCdsl,
        brokerDpPhoneCdsl :   this.state.brokerDpPhoneCdsl,
        brokerDpEmailCdsl :   this.state.brokerDpEmailCdsl,
        brokerDpWebsiteCdsl :   this.state.brokerDpWebsiteCdsl,
        brokerDpComplianceNameCdsl :   this.state.brokerDpComplianceNameCdsl,
        brokerDpCompliancePhoneCdsl :   this.state.brokerDpCompliancePhoneCdsl,
        brokerDpComplianceEmailCdsl :   this.state.brokerDpComplianceEmailCdsl,
        brokerLogoUrl :   this.state.brokerLogoUrl,
        brokerCreatedBy :   this.state.brokerCreatedBy,
        brokerUpdatedBy :  this.state.brokerUpdatedBy,
        brokerClearingMemberName :   this.state.brokerClearingMemberName,
        brokerClearingMemberAddr :   this.state.brokerClearingMemberAddr,
        brokerClearingMemberCity :   this.state.brokerClearingMemberCity,
        brokerClearingMemberState :   this.state.brokerClearingMemberState,
        brokerClearingMemberPin :   this.state.brokerClearingMemberPin,
        brokerClearingMemberPhone :   this.state.brokerClearingMemberPhone,
        brokerClearingMemberFax :  this.state.brokerClearingMemberFax,
        brokerClearingMemberEmail :   this.state.brokerClearingMemberEmail,
        brokerClearingMemberWebsite :   this.state.brokerClearingMemberWebsite,
        brokerDPId :  this.state.brokerDPId,
        brokerSebiRegBseDate :   this.state.brokerSebiRegBseDate,
        brokerSebiRegDpDate :   this.state.brokerSebiRegDpDate,
        brokerSebiRegNoDp :  this.state.brokerSebiRegNoDp,
        brokerSebiRegNseDate :   this.state.brokerSebiRegNseDate,
        brokerShortName :  this.state.brokerShortName,
        brokerSebiRegNo :   this.state.brokerSebiRegNo,
        bseCeoEmail :   this.state.bseCeoEmail,
        bseCeoName :  this.state.bseCeoName,
        bseCeoPhone :   this.state.bseCeoPhone,
        nseCeoEmail :   this.state.nseCeoEmail,
        nseCeoName :  this.state.nseCeoName,
        nseCeoPhone :   this.state.nseCeoPhone,
        brokerStarRating :  this.state.brokerStarRating,
        brokerRecommendedRating :  this.state.brokerRecommendedRating,
        registrationNoNsdl :  this.state.registrationNoNsdl,
        registrationDateNsdl :  this.state.registrationDateNsdl,
        registrationNoCdsl :   this.state.registrationNoCdsl,
        registrationDateCdsl :  this.state.registrationDateCdsl,
        currentValue :  this.state.currentValue,
        endValue :  this.state.endValue,
        incrementBy :  this.state.incrementBy,
        seedValue :  this.state.seedValue,
        series :   this.state.series,
        brokerUccCurrentValue :  this.state.brokerUccCurrentValue,
        brokerUccEndValue :  this.state.brokerUccEndValue,
        brokerUccIncrementBy :  this.state.brokerUccIncrementBy,
        brokerUccSeedValue :  this.state.brokerUccSeedValue,
        brokerUccSeries :  this.state.brokerUccSeries,
        brokerUpcomingFlag :  this.state.brokerUpcomingFlag,
        clearingMemberCinNo :  this.state.clearingMemberCinNo,
        clearingMemberSebiRegNo :  this.state.clearingMemberSebiRegNo,
        brokerGrievanceEmailId :  this.state.brokerGrievanceEmailId,
        brokerKycEmailId :  this.state.brokerKycEmailId,
        boId :  this.state.boId,
        brokerCdslEdisFlag :   this.state.brokerCdslEdisFlag,
        brokerStampUrl :  this.state.brokerStampUrl,
        brokerMailHost :  this.state.brokerMailHost,
        brokerMailId :  this.state.brokerMailId,
        brokerMailPassword :  this.state.brokerMailPassword,
        brokerMailPort :  this.state.brokerMailPort,
        updatedAt: this.state.updatedAt,
        createdAt: this.state.createdAt
      },
        
        )

      .then(res => {
        console.log(res);
        console.log(res.data);
        console.log('thank you')
        var json= JSON.parse(JSON.stringify(res.data.response));
        console.log(json);
        this.setState({sucessMessage: res.data});
      })
    .catch(err => { 
      this.setState({errorMessage: err.message});
    })



  }

  onUpload = (url) => {
    console.log('I have been clicked', url);
    this.setState({brokerLogoUrl:url})
  }

  render() {
    const callbacks = {upload: this.onUpload};
    return (
      <div className="CoomonT mainCt">
      <div className="innerprofilesection">
          <Grid className="CoomonT">
            <div className="clapse ">
      <>
      <Grid className="CoomonT">
        <div className="clapse ">
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary 
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <Button
                  size="small"
                  variant="contained"
                  className="changeBTN"
                  color="primary"
                  disableElevation
                >
                  Add New Broker
                </Button>
</ExpansionPanelSummary>


<ExpansionPanelDetails className="inner_ipo_form add_User">
{ this.state.errorMessage && <Alert severity="error" className="errorMsd">{ this.state.errorMessage }</Alert>}
<form onSubmit={this.handleSubmit}>           
  <div className="formW">
    <TextField type="text" label="broker Name" name="this.state.brokerName" onChange={this.handlebrokerName} required/>
  </div>
  <div className="formW">
     <TextField type="number" label="broker Nse Member Id" name="this.state.brokerNseMemberId" onChange={this.handlebrokerNseMemberId} />
  </div>
  <div className="formW">
    <TextField type="number" label="broker Bse Member Id"  name="this.state.brokerBseMemberId" onChange={this.handlebrokerBseMemberId} />
  </div>
  <div className="formW">
    <TextField type="text" label="broker Nse Exch Name" name="this.state.brokerNseExchName" onChange={this.handlebrokerNseExchName} />
  </div>
  <div className="formW">
    <TextField type="text" label="broker Bse Exch Name" name="this.state.brokerBseExchName" onChange={this.handlebrokerBseExchName} />
  </div>
  <div className="formW">
  <TextField type="number" label="broker Nse Trading Id" name="this.state.brokerNseTradingId" onChange={this.handlebrokerNseTradingId} />
  </div>
  <div className="formW">
  <TextField type="number" label="broker Bse Trading Id" name="this.state.brokerBseTradingId" onChange={this.handlebrokerBseTradingId}/>
  </div>
  <div className="formW">
   <TextField type="url" label="broker Website Url" name="this.state.brokerWebsiteUrl" onChange={this.handlebrokerWebsiteUrl} />
  </div>
  <div className="formW">
    <TextField type="text" label="broker Back office Name" name="this.state.brokerBackofficeName" onChange={this.handlebrokerBackofficeName} />
  </div>
  <div className="formW">
 <TextField type="url" label="broker Back office Url" name="this.state.brokerBackofficeUrl" onChange={this.handlebrokerBackofficeUrl} />
  </div>
  <div className="formW">
   <TextField type="text" label="broker Register Off Add" name="this.state.brokerRegisterOffAdd" onChange={this.handlebrokerRegisterOffAdd} />
  </div>
  <div className="formW">
  <TextField type="text" label="broker Register Off City" name="this.state.brokerRegisterOffCity" onChange={this.handlebrokerRegisterOffCity} />
  </div>
  <div className="formW">
    <TextField type="text" label="broker Register Off State" name="this.state.brokerRegisterOffState" onChange={this.handlebrokerRegisterOffState} />
  </div>
  <div className="formW">
  <TextField type="text" label="broker Register Off Pin" name="this.state.brokerRegisterOffPin" onChange={this.handlebrokerRegisterOffPin} />
  </div>
  <div className="formW">
  <TextField type="tel" label="broker Register Phone" name="this.state.broker Register Phone" onChange={this.handlebrokerRegisterPhone} />
  </div>
  <div className="formW">
   <TextField type="email" label="broker Register Email" name="this.state.brokerRegisterEmail"  onChange={this.handlebrokerRegisterEmail} />
  </div>
  <div className="formW">
   <TextField type="text" label="broker Register Fax" name="this.state.brokerRegisterFax" onChange={this.handlebrokerRegisterFax} />
  </div>
  <div className="formW">
  <TextField type="text" label="broker Correspond Off Add" name="this.state.brokerCorrespondOffAdd" onChange={this.handlebrokerCorrespondOffAdd} />
  </div>
  <div className="formW">
  <TextField type="text" label="broker Correspond Off City" name="this.state.brokerCorrespondOffCity" onChange={this.handlebrokerCorrespondOffCity} />
  </div>
  <div className="formW">
   <TextField type="text" label="broker CorrespondOff State" name="this.state.issue_Type" onChange={this.handlebrokerCorrespondOffState} />
  </div>
  <div className="formW">
  <TextField type="text" label="broker CorrespondOff Pin" name="this.state.logo" value="" onChange={this.handlebrokerCorrespondOffPin} />
  </div>
  <div className="formW">
  <TextField type="text" label="broker Correspond Phone" name="this.state.min_Amt" onChange={this.handlebrokerCorrespondPhone} />
  </div>
<div className="formW">
 <TextField type="email" label="broker Correspond Email" name="this.state.min_Qty" onChange={this.handlebrokerCorrespondEmail} />
  </div>
  <div className="formW">
 <TextField type="text" label="broker Correspond Fax" name="this.state.brokerCorrespondFax" onChange={this.handlebrokerCorrespondFax} />
  </div>
  <div className="formW">
  <TextField type="text" label="broker Compliance Officer Name" name="this.state.brokerComplianceOfficerName" onChange={this.handlebrokerComplianceOfficerName} />
  </div>
  <div className="formW">
  <TextField type="text" label="broker Compliance Officer Phone" name="this.state.brokerComplianceOfficerPhone" onChange={this.handlebrokerComplianceOfficerPhone} />
  </div>
  <div className="formW">
   <TextField type="email" label="broker Compliance Officer Email" name="this.state.brokerComplianceOfficerEmail" onChange={this.handlebrokerComplianceOfficerEmail} />
  </div>
  <div className="formW">
   <TextField type="text" label="broker Ceo Name" name="this.state.brokerCeoName" onChange={this.handlebrokerCeoName} />
  </div>
  <div className="formW">
  <TextField type="text" label="broker Ceo Phone" name="this.state.brokerCeoPhone" onChange={this.handlebrokerCeoPhone} />
  </div>
  <div className="formW">
 <TextField type="text" label="broker Ceo Email" name="this.state.brokerCeoEmail" onChange={this.handlebrokerCeoEmail} />
  </div>
  <div className="formW">
  <TextField type="text" label="broker Clearing Member Info" name="this.state.brokerClearingMemberInfo" onChange={this.handlebrokerClearingMemberInfo} />
  </div>
  <div className="formW">
  <TextField type="text"  label="broker Sebi Reg No Nse" name="this.state.brokerSebiRegNoNse" onChange={this.handlebrokerSebiRegNoNse} />
  </div>

  <div className="formW">
<TextField type="text" label="broker Sebi Reg No Bse" name="this.state.brokerSebiRegNoBse" onChange={this.handlebrokerSebiRegNoBse} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Grievance Cell Info" name="this.state.brokerGrievanceCellInfo" onChange={this.handlebrokerGrievanceCellInfo} />
  </div>
  <div className="formW">
<TextField type="text" label="broker CustomercareN o" name="this.state.brokerCustomercareNo" onChange={this.handlebrokerCustomercareNo} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Customercare Email" name="this.state.brokerCustomercareEmail" onChange={this.handlebrokerCustomercareEmail} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Type Nsdl" name="this.state.brokerDpTypeNsdl" onChange={this.handlebrokerDpTypeNsdl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Status Nsdl" name="this.state.brokerDpStatusNsdl" onChange={this.handlebrokerDpStatusNsdl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Name Nsdl" name="this.state.brokerDpNameNsdl" onChange={this.handlebrokerDpNameNsdl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Address Nsdl" name="this.state.brokerDpAddressNsdl" onChange={this.handlebrokerDpAddressNsdl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp City Nsdl" name="this.state.brokerDpCityNsdl" onChange={this.handlebrokerDpCityNsdl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp State Nsdl" name="this.state.brokerDpStateNsdl" onChange={this.handlebrokerDpStateNsdl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Pin Nsdl" name="this.state.brokerDpPinNsdl" onChange={this.handlebrokerDpPinNsdl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Phone Nsdl" name="this.state.brokerDpPhoneNsdl" onChange={this.handlebrokerDpPhoneNsdl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Email Nsdl" name="this.state.brokerDpEmailNsdl" onChange={this.handlebrokerDpEmailNsdl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Website Nsdl" name="this.state.brokerDpWebsiteNsdl" onChange={this.handlebrokerDpWebsiteNsdl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Compliance Name Nsdl" name="this.state.brokerDpComplianceNameNsdl" onChange={this.handlebrokerDpComplianceNameNsdl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker_dp Compliance Phone Nsdl" name="this.state.broker_dpCompliancePhoneNsdl" onChange={this.handlebroker_dpCompliancePhoneNsdl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Compliance Email Nsdl" name="this.state.brokerDpComplianceEmailNsdl" onChange={this.handlebrokerDpComplianceEmailNsdl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Type Cdsl" name="this.state.brokerDpTypeCdsl" onChange={this.handlebrokerDpTypeCdsl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Status Cdsl" name="this.state.brokerDpStatusCdsl" onChange={this.handlebrokerDpStatusCdsl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Name Cdsl" name="this.state.brokerDpNameCdsl" onChange={this.handlebrokerDpNameCdsl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Address Cdsl" name="this.state.brokerDpAddressCdsl" onChange={this.handlebrokerDpAddressCdsl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp City Cdsl" name="this.state.brokerDpCityCdsl" onChange={this.handlebrokerDpCityCdsl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp State Cdsl" name="this.state.brokerDpStateCdsl" onChange={this.handlebrokerDpStateCdsl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Pin Cdsl" name="this.state.brokerDpPinCdsl" onChange={this.handlebrokerDpPinCdsl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Phone Cdsl" name="this.state.brokerDpPhoneCdsl" onChange={this.handlebrokerDpPhoneCdsl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Email Cdsl" name="this.state.brokerDpEmailCdsl" onChange={this.handlebrokerDpEmailCdsl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Website Cdsl" name="this.state.brokerDpWebsiteCdsl" onChange={this.handlebrokerDpWebsiteCdsl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Compliance NameCdsl" name="this.state.brokerDpComplianceNameCdsl" onChange={this.handlebrokerDpComplianceNameCdsl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Compliance Phone Cdsl" name="this.state.brokerDpCompliancePhoneCdsl" onChange={this.handlebrokerDpCompliancePhoneCdsl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Dp Compliance Email Cdsl" name="this.state.brokerDpComplianceEmailCdsl" onChange={this.handlebrokerDpComplianceEmailCdsl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Logo Url" name="this.state.brokerLogoUrl" onChange={this.handlebrokerLogoUrl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Created By" name="this.state.brokerCreatedBy" onChange={this.handlebrokerCreatedBy} />
  </div>
  <div className="formW">
<TextField type="text" label="broker UpdatedBy" name="this.state.brokerUpdatedBy" onChange={this.handlebrokerUpdatedBy} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Clearing Member Name" name="this.state.brokerClearingMemberName" onChange={this.handlebrokerClearingMemberName} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Clearing Member Addr" name="this.state.brokerClearingMemberAddr" onChange={this.handlebrokerClearingMemberAddr} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Clearing Member City" name="this.state.brokerClearingMemberCity" onChange={this.handlebrokerClearingMemberCity} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Clearing Member State" name="this.state.brokerClearingMemberState" onChange={this.handlebrokerClearingMemberState} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Clearing Member Pin" name="this.state.brokerClearingMemberPin" onChange={this.handlebrokerClearingMemberPin} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Clearing Member Phone" name="this.state.brokerClearingMemberPhone" onChange={this.handlebrokerClearingMemberPhone} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Clearing Member Fax" name="this.state.brokerClearingMemberFax" onChange={this.handlebrokerClearingMemberFax} />
  </div>
  <div className="formW">
<TextField type="email" label="broker Clearing Member Email" name="this.state.brokerClearingMemberEmail" onChange={this.handlebrokerClearingMemberEmail} />
  </div>
  <div className="formW">
<TextField type="url" label="broker Clearing Member Website" name="this.state.brokerClearingMemberWebsite" onChange={this.handlebrokerClearingMemberWebsite} />
  </div>
  <div className="formW">
<TextField type="text" label="broker DP Id" name="this.state.brokerDPId" onChange={this.handlebrokerDPId} />
  </div>
  <div className="formW">
<TextField type="date" label="brokerSebiRegBseDate" name="this.state.brokerSebiRegBseDate" onChange={this.handlebrokerSebiRegBseDate} />
  </div>
  <div className="formW">
<TextField type="date" label="broker Sebi Reg Dp Date" name="this.state.brokerSebiRegDpDate" onChange={this.handlebrokerSebiRegDpDate} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Sebi Reg No Dp" name="this.state.brokerSebiRegNoDp" onChange={this.handlebrokerSebiRegNoDp} />
  </div>
  <div className="formW">
<TextField type="date" label="broker Sebi Reg Nse Date" name="this.state.brokerSebiRegNseDate" onChange={this.handlebrokerSebiRegNseDate} />
  </div>


  <div className="formW">
<TextField type="text" label="broker Short Name" name="this.state.brokerShortName" onChange={this.handlebrokerShortName} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Sebi Reg No" name="this.state.brokerSebiRegNo" onChange={this.handlebrokerSebiRegNo} />
  </div>
  <div className="formW">
<TextField type="email" label="bse Ceo Email" name="this.state.bseCeoEmail" onChange={this.handlebseCeoEmail} />
  </div>
  <div className="formW">
<TextField type="text" label="bse Ceo Name" name="this.state.bseCeoName" onChange={this.handlebseCeoName} />
  </div>
  <div className="formW">
<TextField type="text" label="bse Ceo Phone" name="this.state.bseCeoPhone" onChange={this.handlebseCeoPhone} />
  </div>
  <div className="formW">
<TextField type="email" label="nseCeo Email" name="this.state.nseCeoEmail" onChange={this.handlenseCeoEmail} />
  </div>
  <div className="formW">
<TextField type="text" label="nseCeo Name" name="this.state.nseCeoName" onChange={this.handlenseCeoName} />
  </div>
  <div className="formW">
<TextField type="text" label="nseCeo Phone" name="this.state.nseCeoPhone" onChange={this.handlenseCeoPhone} />
  </div>
  <div className="formW">
<TextField type="number" label="broker Star Rating" name="this.state.brokerStarRating" onChange={this.handlebrokerStarRating} />
  </div>
  <div className="formW">
<TextField type="number" label="broker Recommended Rating" name="this.state.brokerRecommendedRating" onChange={this.handlebrokerRecommendedRating} />
  </div>
  <div className="formW">
<TextField type="text" label="registration No Nsdl" name="this.state.registrationNoNsdl" onChange={this.handleregistrationNoNsdl} />
  </div>
  <div className="formW">
<TextField type="number" label="registration Date Nsdl" name="this.state.registrationDateNsdl" onChange={this.handleregistrationDateNsdl} />
  </div>
  <div className="formW">
<TextField type="text" label="registration No Cdsl" name="this.state.registrationNoCdsl" onChange={this.handleregistrationNoCdsl} />
  </div>
  <div className="formW">
<TextField type="number" label="registration Date Cdsl" name="this.state.registrationDateCdsl" onChange={this.handleregistrationDateCdsl} />
  </div>
  <div className="formW">
<TextField type="text" label="current Value" name="this.state.currentValue" onChange={this.handlecurrentValue} />
  </div>
  <div className="formW">
<TextField type="text" label="end Value" name="this.state.endValue" onChange={this.handleendValue} />
  </div>
  <div className="formW">
<TextField type="text" label="increment By" name="this.state.incrementBy" onChange={this.handleincrementBy} />
  </div>
  <div className="formW">
<TextField type="text" label="seed Value" name="this.state.seedValue" onChange={this.handleseedValue} />
  </div>
  <div className="formW">
<TextField type="text" label="series" name="this.state.series" onChange={this.handleseries} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Ucc Current Value" name="this.state.brokerUccCurrentValue" onChange={this.handlebrokerUccCurrentValue} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Ucc End Value" name="this.state.brokerUccEndValue" onChange={this.handlebrokerUccEndValue} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Ucc Increment By" name="this.state.brokerUccIncrementBy" onChange={this.handlebrokerUccIncrementBy} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Ucc Seed Value" name="this.state.brokerUccSeedValue" onChange={this.handlebrokerUccSeedValue} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Ucc Series" name="this.state.brokerUccSeries" onChange={this.handlebrokerUccSeries} />
  </div>
  <div className="formW">
<TextField type="number" label="broker Upcoming Flag" name="this.state.brokerUpcomingFlag" onChange={this.handlebrokerUpcomingFlag} />
  </div>
  <div className="formW">
<TextField type="text" label="clearing Member Cin No" name="this.state.clearingMemberCinNo" onChange={this.handleclearingMemberCinNo} />
  </div>
  <div className="formW">
<TextField type="text" label="clearing Member Sebi Reg No" name="this.state.clearingMemberSebiRegNo" onChange={this.handleclearingMemberSebiRegNo} />
  </div>
  <div className="formW">
<TextField type="email" label="broker Grievance EmailI d" name="this.state.brokerGrievanceEmailId" onChange={this.handlebrokerGrievanceEmailId} />
  </div>
  <div className="formW">
<TextField type="email" label="broker Kyc Email Id" name="this.state.brokerKycEmailId" onChange={this.handlebrokerKycEmailId} />
  </div>
  <div className="formW">
<TextField type="text" label="boId" name="this.state.boId" onChange={this.handleboId} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Cdsl Edis Flag" name="this.state.brokerCdslEdisFlag" onChange={this.handlebrokerCdslEdisFlag} />
  </div>
  <div className="formW">
<TextField type="url" label="broker Stamp Url" name="this.state.brokerStampUrl" onChange={this.handlebrokerStampUrl} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Mail Host" name="this.state.brokerMailHost" onChange={this.handlebrokerMailHost} />
  </div>
  <div className="formW">
<TextField type="email" label="broker Mail Id" name="this.state.brokerMailId" onChange={this.handlebrokerMailId} />
  </div>
  <div className="formW">
<TextField type="password" label="broker Mail Password" name="this.state.brokerMailPassword" onChange={this.handlebrokerMailPassword} />
  </div>
  <div className="formW">
<TextField type="text" label="broker Mail Port" name="this.state.brokerMailPort" onChange={this.handlebrokerMailPort} />
  </div>
 
  <br/>
  <div className="formW">
    <label>Upload Logo</label>
  <TradeImportComponent  {...callbacks} />
  </div>
  



  <br/>
  {/* <button type="submit" color="primary">Add</button> */}
  <div className="form-btn">
  <Button
                  size="small"
                  variant="contained"
                  className=""
                  color="primary"
                  type="submit"
                  disableElevation
                >
                  Submit
                </Button>
                </div>
                
</form>
            </ExpansionPanelDetails>


          </ExpansionPanel>
        </div>

      </Grid>
      </>
    </div>
          </Grid>
          </div>
          </div>

    )
  }
}
export default Ipo;
