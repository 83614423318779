import React from 'react';
import axios from 'axios';
import { Grid, Button } from "@material-ui/core/";
// const FormItem = Form.Item;
import TextField from "@material-ui/core/TextField";
// import Link from '@material-ui/core/Link';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from "@material-ui/icons/EditRounded";
class FaqPage extends React.Component<any, any>{
  constructor(props) {
    super(props)
    this.state = {
      status: '',
    }
    // this.state = { values: [{question: '',response: ''}] };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }


  componentDidUpdate() {
    setTimeout(() => this.setState({ errorMessage: '' }), 40000);
    setTimeout(() => this.setState({ sucessMessage: '' }), 40000);
  }
  uploadDocument = (e) => {
    console.log(e);
  }



  handlequestionChange = event => { this.setState({ status: event.target.value }) }
  handleanswerNameChange = event => {
    this.setState({ answer: event })
  }

  handleSubmit = event => {
    event.preventDefault();
    event.target.reset()
    console.log(this.props.rowId);
    let rId = this.props.rowId;
    const posts = rId;
    console.log(posts);
    // Based on several assumptions: single obj returned, posts is never empty
    // https://gatewayapi-uat.invest19.com/api/save/allotment/status?id=1599&userId=7277
    axios.post(`https://gatewayapi.invest19.com/api/save/allotment/status?id=${rId}`,
      {
        // id: this.props.rowId,
            status: this.state.status,
      }
    ).then(res => {
      this.getData();
      this.setState({ status: '' })
    })
      .catch(err => {
        this.setState({ errorMessage: err.message });
      })
  }
  // getNotification() {
  //   let rId = this.props.rowId;
  //   axios.get(`https://gatewayapi.invest19.com/notification/send/ipo/${rId}?mobiles=`)

  // };
  handleUpdate = event => {
    event.preventDefault();
    event.target.reset()
    console.log(this.props.rowId);
    let rId = this.props.rowId;
    const posts = rId;
    console.log(posts);
    // Based on several assumptions: single obj returned, posts is never empty
    axios.post(`https://gatewayapi.invest19.com/api/${rId}?mobiles`,
      {
        ipoId: this.props.rowId,
        faqList: [
          {
            id: this.state.editId,
            question: this.state.question,
            answer: this.state.answer,
          }
        ]
      }
    ).then(res => {
      this.getData();
      this.setState({ question: '' })
      this.setState({ answer: '' })
      this.setState({ edit: false })
    })
    // this.setState({ posts });

    // .catch(err => {
    //   this.setState({ errorMessage: err.message });
    // })
  }

  componentDidMount() {
    this.getData()
  };

  getData() {
    let rId = this.props.rowId;
    axios.get(`https://gatewayapi.invest19.com/api/${rId}`)
      .then(res => {
        const faq = res.data.response;
        this.setState({ faq });
      })
  };

  delete(id) {
    fetch(`https://gatewayapi-uat.invest19.com/api/` + id,
      {
        method: 'DELETE',
      }).then(res => {
        this.getData();
      })

  }
  edit(faq) {
    this.setState({ question: faq.question });
    this.setState({ answer: faq.answer });
    this.setState({ edit: true });
    this.setState({ editId: faq.id })

  }

  // createUI() {
  //   return this.state.values.map((el, i) =>

  //   )
  // }


  handleChange(i, event) {
    let values = [...this.state.values];
    values[i][event.target.id] = event.target.value;
    this.setState({ values });
  }

  addClick() {
    this.setState(prevState => ({ values: [...prevState.values, { question: '', response: '', }] }))
  }

  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  }




  // componentDidMount() {
  //   console.log(this.props.rowId);
  //   let rId = this.props.rowId;
  //   axios.get(`https://gatewayapi-uat.invest19.com/api/faqs/${rId}`)
  //     .then(res => {
  //       const posts = res.data;
  //       this.setState({ posts });
  //     })
  // }


  //   async componentDidMount() {  
  //     let rIdD = this.props.rowId;   
  //     const { data } = await axios.get('https://gatewayapi-uat.invest19.com/api/faqs/${rIdD}')
  //     this.setState({posts: data, isLoading: false})
  //     console.log(this.state.posts)
  // }



  render() {
    return (

      <Grid className="CoomonT">
        <div className="clapse " id="top">
          <form onSubmit={this.state.edit ? this.handleUpdate : this.handleSubmit}>
            <div className="commonInput">
              <div className="formW">

                <TextField type="text" label="status" value={this.state.status} name="status" onChange={this.handlequestionChange} />
              </div>
            
            </div>

            <div className="form-btn formW">
              <Button
                size="small"
                variant="contained"
                className=""
                color="primary"
                type="submit"
                disableElevation
              >
                {this.state.edit ? 'Update' : 'Submit'}

              </Button>
            </div>

          </form>
        </div>

        {/* {this.state.faq &&
          <div className="faqListC">
            {this.state.faq.map(faqList => (
              <div className="faqList">
                <div className="qNum">Question: <b>{faqList.question}</b>
                  <div>
                    <Button onClick={() => { this.delete(faqList.id); }} color="primary" size="small" autoFocus>
                      <DeleteRoundedIcon fontSize="small" />
                    </Button>
                    <Button onClick={() => { this.edit(faqList); }} color="primary" href="#top" size="small" autoFocus>
                      <EditRoundedIcon fontSize="small" />
                    </Button>
                  </div>
                </div>

                <div className="aNum">
                  <span className="ansD">Ans.: </span>
                  <div
                    className="innerF"
                    dangerouslySetInnerHTML={{
                      __html: faqList.answer
                    }}></div>

                </div>
              </div>
            ))}

          </div>} */}



      </Grid>






    )
  }
}
export default FaqPage;
