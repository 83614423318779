import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  SHOW_MESSAGE_SUCCESS,
  USER_LOGOUT_SUCCESS,
  FALSE_SHOW_MESSAGE,
} from "../actionTypes";

const istate = {
  fetchingUserLogin: false,
  fetchedUserLogin: false,
  message: "",
  statusCode: "",
  showMessage: false,
  data: "",
};

export default function userLoginReducer(state = istate, action) {
  switch (action.type) {
    case USER_LOGIN: {
      return {
        ...state,
        fetchingUserLogin: true,
        fetchedUserLogin: false,
      };
    }
    case USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        statusCode: action.payload,
        showMessage: false,
      };
    }
    case USER_LOGIN_SUCCESS: {
      return {
        ...state,
        //columns: Object.keys(action.payload.columns[0]),
        fetchingUserLogin: false,
        fetchedUserLogin: true,
        showMessage: true,
        data: action.payload.body.data,
        message: action.payload.body.statusOfRequest.message,
        statusCode: action.payload.body.statusOfRequest.statusCode,
      };
    }
    case USER_LOGIN_FAILURE: {
      return {
        ...state,
        fetchedUserLogin: false,
        userLoginError: action.payload,
        message: action.payload,
        showMessage: true,
        statusCode: 1,
      };
    }
    case SHOW_MESSAGE_SUCCESS: {
      return {
        ...state,
        showMessage: true,
      };
    }
    case FALSE_SHOW_MESSAGE: {
      return {
        ...state,
        showMessage: false,
      };
    }
    default:
      return state;
  }
}
