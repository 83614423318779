import React, { ReactElement } from "react";
import { withRouter } from "react-router-dom";
import routes from "../../Routes";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddingT: {
      paddingTop: "84px",
    },
    paddingNone: {
      padding: "0px !important",
      overflow: "initial !important",
    },
  })
);

function ContentComponent(props: any): ReactElement {
  const classes = useStyles();
  return (
    <main
      className={`ToolbarP ${
        props.location.pathname === "/login"
          ? classes.paddingNone
          : classes.paddingT
      }`}
    >
      {routes}
    </main>
  );
}
export default withRouter(ContentComponent);
