import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import UserListUseCaseHanlder from "../../../usecase/UserListUseCaseHandler";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  })
);

export default function InfoMessage(props: any) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const { showMessage } = useSelector(
    (state: any) => ({
      showMessage: state.userLoginReducer.showMessage,
    }),
    shallowEqual
  );

  useEffect(() => {
    setTimeout(() => {
      dispatch(UserListUseCaseHanlder.falseShowMessage());
    }, 2000);
  }, [showMessage]);
  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          className="alert-msg"
          severity={props.success ? "success" : "error"}
        >
          {props.message}
        </Alert>
      </Collapse>
    </div>
  );
}
