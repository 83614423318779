import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Paper } from "@material-ui/core";
import InfoMessage from "../../Form/Message/InfoMessage";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
const Search = ({ onSearch }) => {
  const [search, setSearch] = useState("");
  const onInputChange = (value) => {
    setSearch(value);
    onSearch(value);
  };

  const { statusCode, message, showMessage } = useSelector(
    (state: any) => ({
      message: state.userListsReducer.message,
      statusCode: state.userListsReducer.statusCode,
      showMessage: state.userListsReducer.showMessage,
    }),
    shallowEqual
  );

  return (
    <div>
      <Grid container className="infoContainer">
        <Grid item xs={8}>
          <Paper className="message-box">
            {showMessage && statusCode !== "" ? (
              showMessage && statusCode === 200 ? (
                <InfoMessage success={true} message={message} />
              ) : (
                <InfoMessage success={false} message="Something Went Wrong" />
              )
            ) : null}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className="search">
            <TextField
              type="text"
              className="form-control"
              style={{ width: "180px" }}
              placeholder="Search Call"
              value={search}
              onChange={(e) => onInputChange(e.target.value)}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Search;
