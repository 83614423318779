import React, { Component } from 'react';
import axios from 'axios';
// import Hero from './Hero';

class DashboardComponent extends Component {
  data: any;
  constructor(props) {
    super(props);
    this.state = {
      heroes: [],
      loading: true,
      error: false,
    };
  }
  // const heroes = [
  //   {
  //     "id": 0,
  //     "name": "John Smith",
  //     "speciality": "Wizard"
  //   },
  //   {
  //     "id": 1,
  //     "name": "Crag Hack",
  //     "speciality": "Viking"
  //   },
  //   {
  //     "id": 2,
  //     "name": "Silvio",
  //     "speciality": "Warrior"
  //   }
  // ];

  componentDidMount() {
    axios.get('https://gatewayapi.invest19.com/broker/get/list')
      .then(res => {
        const heroes = res.data;
        this.setState({ heroes, loading: false });
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      })
  }
  render() {

    if (this.data.id || !this.data.id) {
      return (
        <div>
          <p> An error occured </p>
        </div>
      )
    }
    return (
      <div> 

      </div>
    );
  }
}


export default DashboardComponent;
