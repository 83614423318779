import Axios from "axios";
import axios from "axios";

// const ADV_BASE_URL =
//   "https://cors-anywhere.herokuapp.com/http://am-research-api.eba-zcep6zj5.ap-south-1.elasticbeanstalk.com/";



const ADV_BASE_URL =
  "https://gatewayapi.invest19.com";

 
const BASE_URL = 
  "https://gatewayapi.invest19.com";
const FIND_LIST = "/api/get/all?filter=Upcoming,Ongoing,Closed";




const SAVE_SCRIP = "research/save/scrip";
const SEARCH_SCRIP = "find/symbol";
const SELECT_CATEGORY_LIST = "research/find/scrip/categories";
const LOGIN_END_POINT = "/user/auth/superadmin/login";
const MODIFY_SCRIP = "research/modify/scrip";
const CALL_TYPE = "find/category";
const EXCHANGE = "find/exchange";
const MARKET = "find/market";
const INSTRUMENT_TYPE = "find/instrument";
const EXPIRY_DATE = "find/expiryDate";
const STRIKE_PRICE = "find/strikePrice";
const OPTION_TYPE = "find/optionType";
const CATEGORY_LIST = "find/categoriesList";
const TARGET_PRICE = "find/targetprice";
const STOP_LOSS_PRICE = "find/stoploss";
const ADD_CALL = "save/callDetails";
const SIGNUP = "save/admin/signupdetails";
const ADMIN_DETAIL = "find/admindetails";
const FUND_WITHDRAWAL = "store/admin/withdrawal/amount";



// partner admin axios end point
const IPO_LIST = "/super/get/business/partner/list?page_from=0&page_to=1000000";
const MODIFY_IPO = "/super/edit/new/business/partner";
// partner admin axios end point

// Application list 
const ApplicationApiData = "/user/get/ipo/details/list?page_from=0&page_to=1000000000";
// Application list 


// revenue admin axios end point
const REVENUE_LIST = "/super/get/all/revenue/partner/details?page_from=0&page_to=1000000";
const MODIFY_REVENUE = "/super/edit/new/business/partner";
// partner admin axios end point



const token = localStorage.getItem('auth_token');
Axios.defaults.headers.common['Authorization'] = `Bearer ${token} ' '`;



// super admin end points

const SEARCH_LIST = "/user/search";
const GENERATE_CODE = "/referrals/generate/code";

// broker list
const ALL_BROKER_LIST = "/broker/get/list";
const ALL_BROKER_EDIT = "/broker/edit/broker";


//guest list
const ALL_GUEST_LIST = "/user/find/all/guest/users?page_from=1&page_to=1000000000";







// const ALL_BROKER_DELETE = "/broker/get/list";
// broker list
class ApiService {
  
  searchList(data) {
    return axios.post(ADV_BASE_URL + SEARCH_LIST, data);
  }

  generateCode(data) {
    return axios.post(BASE_URL + GENERATE_CODE, data);
  }
  

// broker list
  allBrokerList() {
    const token = localStorage.getItem('auth_token');
    return axios.get(BASE_URL + ALL_BROKER_LIST,  ({ headers: {Authorization : `Bearer ${token}`}}));
  }
  AllBrokerEdit(data) {
    const token = localStorage.getItem('auth_token');
    return axios.put(BASE_URL + ALL_BROKER_EDIT, data,  ({ headers: {Authorization : `Bearer ${token}`}}));
  }
// broker list


// Guest List
allGuestList(fromDate, toDate) {
  // debugger;
  // console.log(localStorage.getItem("auth_token"),"Token in api call");
  const token = localStorage.getItem('auth_token');
  return axios.get(BASE_URL + ALL_GUEST_LIST + '&localDateFrom=' + (fromDate ? fromDate : '') + '&localDateTo=' + (toDate ? toDate : ''), { headers: {Authorization : `Bearer ${token}`}} );
}
// Guest List


// partner admin axios

fetchAllDataIPO() {
  return axios.get(BASE_URL + IPO_LIST);
}
modifyIPOData(data) {
  return axios.post(BASE_URL + MODIFY_IPO, data);
}
// partner admin axios


// ipo application
fetchAllApplicationApiData() {
  return axios.get(BASE_URL + ApplicationApiData);
}
// ipo application





// revenue admin axios

fetchAllDatarevenue() {
  return axios.get(BASE_URL + REVENUE_LIST);
}
modifyrevenueData(data) {
  return axios.post(BASE_URL + MODIFY_IPO, data);
}
// revenue admin axios







  fetchAllData() {
    const token = localStorage.getItem('auth_token');
    return axios.get(BASE_URL + ADMIN_DETAIL,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  signUp(data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(ADV_BASE_URL + SIGNUP, data,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  

  fetchCallType() {
    const token = localStorage.getItem('auth_token');
    return axios.get(ADV_BASE_URL + CALL_TYPE,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  fetchExchange() {
    const token = localStorage.getItem('auth_token');
    return axios.get(ADV_BASE_URL + EXCHANGE,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  fetchMarket() {
    const token = localStorage.getItem('auth_token');
    return axios.get(ADV_BASE_URL + MARKET,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  fetchInst(data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(ADV_BASE_URL + INSTRUMENT_TYPE, data,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  fetchExpDate(data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(ADV_BASE_URL + EXPIRY_DATE, data,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  fetchOptionType(data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(ADV_BASE_URL + OPTION_TYPE, data,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  fetchTargetPrice(data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(ADV_BASE_URL + TARGET_PRICE, data,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  fetchStrikePrice(data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(ADV_BASE_URL + STRIKE_PRICE, data,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  fetchStopLossPrice(data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(ADV_BASE_URL + STOP_LOSS_PRICE, data,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  addCall(data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(ADV_BASE_URL + ADD_CALL, data,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  fetchCategoriesList(data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(ADV_BASE_URL + CATEGORY_LIST, data,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  researchLogin(values) {
    const token = localStorage.getItem('auth_token');
    return axios.post(
      `${ADV_BASE_URL}${LOGIN_END_POINT}?adminId=${values.adminId}&adminPassword=${values.adminPassword}`
    );
  }

  modifyScripData(data) {
    const token = localStorage.getItem('auth_token');
    return axios.put(ADV_BASE_URL + MODIFY_SCRIP, data,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }
  closeScripData(data) {
    const token = localStorage.getItem('auth_token');
    return axios.put(ADV_BASE_URL + MODIFY_SCRIP, data,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  publishScripData(data) {
    const token = localStorage.getItem('auth_token');
    return axios.put(ADV_BASE_URL + MODIFY_SCRIP, data,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  saveScripData(saveScrip) {
    const token = localStorage.getItem('auth_token');
    return axios.post(ADV_BASE_URL + SAVE_SCRIP, saveScrip,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }

  searchScripDataList(searchScrip) {
    const token = localStorage.getItem('auth_token');
    return axios.post(ADV_BASE_URL + SEARCH_SCRIP, searchScrip,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }
  categoryDataList(categoryList) {
    const token = localStorage.getItem('auth_token');
    return axios.post(ADV_BASE_URL + SELECT_CATEGORY_LIST, categoryList,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }
  findAdminDetail(data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(ADV_BASE_URL + ADMIN_DETAIL, data,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }
  fundWithdrawalAmount(data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(ADV_BASE_URL + FUND_WITHDRAWAL, data,  ({ headers: {Authorization : `Bearer ${token}`}}) );
  }
}

export default new ApiService();
