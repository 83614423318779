import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Grid, Container, Paper, IconButton } from "@material-ui/core/";
import { UserLogin } from "../../adapter/usecase/UserLogIn";
import FormikField from "../Form/FormikField/index";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import "./Login.scss";
// import ApiService from "../../api/ApiService";
import InfoMessage from "../Form/Message/InfoMessage";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import UserLoginUseCaseHandler from "../../usecase/UserLoginUseCaseHandler";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import logoo from "../../assets/images/invest-19-logo2.svg";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  })
);

const initialValues: UserLogin = {
  adminId: "",
  adminPassword: "",
};

const LoginSchema = Yup.object().shape({
  adminId: Yup.string().min(3, "User Id is too short").required("Required"),
  adminPassword: Yup.string()
    .min(3, "Password is too short")
    .required("Required"),
});

const Login: React.FC = (props: any) => {
  //const [token, setToken] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [adminId, setAdminId] = React.useState("");

  const { statusCode, showMessage, userData, message } = useSelector(
    (state: any) => ({
      statusCode: state.userLoginReducer.statusCode,
      showMessage: state.userLoginReducer.showMessage,
      userData: state.userLoginReducer.data,
      message: state.userLoginReducer.message,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const handlePassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const userLoginFn = (values: UserLogin) => {
    dispatch(UserLoginUseCaseHandler.userLogin(values));
  };
  const handleSubmit = (values: UserLogin): any => {
    userLoginFn(values);
    console.log(values, "values");
    setAdminId(values.adminId);

    setOpen(true);
  };

  useEffect(() => {
    if (statusCode !== "") {
      if (statusCode == 0) {
        localStorage.clear();
        localStorage.setItem("username", JSON.stringify(userData[0].username));
        localStorage.setItem("data", JSON.stringify(userData[0].role));
        localStorage.setItem("auth_token", JSON.stringify(userData[0].auth_token).slice(1, -1));
        localStorage.setItem(
          "lastLogin",
          JSON.stringify(userData[0].lastlogin)
        );
        localStorage.setItem(
          "bowSessionID",
          JSON.stringify(userData[0].bowSessionID)
        );
        props.history.push("/");
      } else {
        localStorage.clear();
        props.history.push("/login");
      }
    }
  }, [statusCode, userData]);

  const classes = useStyles();
  return (
    <Container maxWidth="xl" className="commonS">
      <Grid container spacing={6} className="signin-container">
        <Grid item xs={4} className="content_side_userLS">
          <h1 className="headingTXT">Broker Super Admin</h1>
          <img title="invest19" src={logoo} alt="invest19" />
          <h1>Let’s start by filling in your Login details</h1>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <div className="signin-box">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={LoginSchema}
              >
                {({ dirty, isValid }) => {
                  return (
                    <Form>
                      <FormikField
                        name="adminId"
                        label="User ID"
                        type="text"
                        required
                      />
                      <div className="passwordWithMask">
                        <FormikField
                          name="adminPassword"
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          required
                        />
                        <span className="eye-icon" onClick={handlePassword}>
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </span>
                      </div>

                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!dirty || !isValid}
                        type="submit"
                      >
                        Login
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
              {statusCode !== "" && showMessage && userData == "S" ? (
                statusCode == 200 ? (
                  <InfoMessage success={true} message={message} />
                ) : (
                  <InfoMessage success={false} message={message} />
                )
              ) : null}
              {/* <div className="difbtn">
                <p>Have not account yet?</p>
              <Link className="signuplink" color="primary" to="/signup">
                Sign up
              </Link>
              </div> */}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withRouter(Login);
