import React from 'react';
import axios from 'axios';
// import { Form } from 'antd';
// import { List, Card, Form } from 'antd';
import { Grid, Button, MenuItem } from "@material-ui/core/";
import Alert from '@material-ui/lab/Alert';
// const FormItem = Form.Item;
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import TradeImportComponent from "../Ipo/AddLogo";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import AgentMaster from "../revenue/AgentMaster";
import { FormControl } from "@material-ui/core/";
import InputLabel from "@material-ui/core/InputLabel";
import { Select } from "@material-ui/core/";
// import AutoCompleteScrip from "../User/AutoCompleteScrip";
class revenueModalOne extends React.Component<{}, any>{
  // export default class ipo extends React.Component {

  custom_file_upload_url = `https://gatewayapi-uat.invest19.com/api/ipo/document/upload`;
  canvas: any;

  constructor(props) {
    super(props)
    this.state = { checked: false };
    this.canvas = React.createRef()
    this.state = {
      businessPartner: '',
      product: '',
      ipoName: '',
      revenueModel: [{
        bidFrom: '',
        bidTo: '',
        upTo10x: '',
        upTo20x: '',
        upTo50x: '',
        above50x: ''
      }],

      revenuePerApplicationList: [{
        bidFrom: '',
        bidTo: '',
        upTo10x: '',
        upTo20x: '',
        upTo50x: '',
        above50x: ''
      }],
      
    }
  }
  componentDidUpdate() {
    setTimeout(() => this.setState({ errorMessage: '' }), 40000);
    setTimeout(() => this.setState({ sucessMessage: '' }), 40000);
  }



  handleCmpdData = (data) => {
    this.setState({
      currentMarketPrice: data,
    });
  };
  toggleReset = (value) => {
    this.setState({
      resetForm: value,
    });
  };
  // Image Preview Handler
  handleImagePreview = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0])
    let image_as_files = e.target.files[0];

    this.setState({
      image_preview: image_as_base64,
      image_file: image_as_files,
    })
  }

  // Image/File Submit Handler
  handleSubmitFileUpload = () => {

    if (this.state.image_file !== null) {

      let formData = new FormData();
      formData.append('ipoName', 'tata');
      formData.append('document', this.state.image_file);

      axios.post(
        this.custom_file_upload_url,
        formData,
        {
          headers: {
            // "Authorization": "YOUR_API_AUTHORIZATION_KEY_SHOULD_GOES_HERE_IF_HAVE",
            "Content-type": "multipart/form-data",
          },
        }
      )
        .then(res => {
          console.log(`Success` + res.data);
          var json = JSON.parse(JSON.stringify(res.data));
          console.log(json);
          console.log(json.url);
          console.log(this.canvas.current.value);
          // this.canvas.current.value=json.url;
          // console.log(this.canvas.current.value);
          return json.url;
        })
        .catch(err => {
          console.log(err);
        })
    }
  }



  uploadDocument = (e) => {
    console.log(e);
  }


  handlebusinessPartnerChange = event => { this.setState({ businessPartner: event.target.value }) }
  handleproductChange = event => { this.setState({ product: event.target.value }) }
  handleipoNameChange = event => { this.setState({ ipoName: event.target.value }) }
  handlerevenueModelChange = event => { this.setState({ revenueModel: event.target.value }) }
  handlebidFromChange = event => { this.setState({ bidFrom: event.target.value }) }
  handlebidToChange = event => { this.setState({ bidTo: event.target.value }) }
  handleupTo10xChange = event => { this.setState({ upTo10x: event.target.value }) }
  handleupTo20xChange = event => { this.setState({ upTo20x: event.target.value }) }
  handleupTo50xChange = event => { this.setState({ upTo50x: event.target.value }) }
  handleabove50xChange = event => { this.setState({ above50x: event.target.value }) }



  // handleapplicationSeriesApplied  =   event => {this.setState({ applicationSeriesApplied: event.target.value })}

  handleapplicationSeriesApplied = () => {
    this.setState({ applicationSeriesApplied: !this.state.false });
  }
  // handleabout_Company = event => {this.setState({ about_Company: event.target.value })}

  handleSubmit = event => {
    event.preventDefault();
    event.target.reset()

    // const openDate = {userid: this.state.openDate};
    // const closeDate = {fullname: this.state.closeDate};
    // const listingDate = {usergroup: this.state.listingDate};



    axios.post('https://gatewayapi.invest19.com/super/add/new/revenue/model',
      { businessPartner: this.state.businessPartner, 
        product: this.state.product, 
        ipoName: this.state.ipoName,
        revenueModel: this.state.revenueModel,

        revenuePerApplicationList: [
          {
            bidFrom: this.state.bidFrom,
            bidTo: this.state.bidTo,
            upTo10x: this.state.upTo10x,
            upTo20x: this.state.upTo20x,
            upTo50x: this.state.upTo50x,
            above50x: this.state.above50x,
          }
        ],
      },

    )

      .then(res => {
        console.log(res);
        console.log(res.data);
        console.log('thank you')
        this.setState({ sucessMessage: res.data });
      })
      .catch(err => {
        this.setState({ errorMessage: err.message });
      })
  }


  onUpload = (url) => {
    console.log('I have been clicked', url);
    this.setState({ logo: url })
  }


  render() {

    const callbacks = { upload: this.onUpload };
    return (
      <div className="CoomonT mainCt">
        <div className="innerprofilesection">
          <Grid className="CoomonT">
            <div className="clapse ">
              <ExpansionPanel className="newUiupadte">
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Button
                    size="small"
                    variant="contained"
                    className="changeBTN"
                    color="primary"
                    disableElevation
                  >
                    Add revenue
                  </Button>
                </ExpansionPanelSummary>


                <ExpansionPanelDetails className="inner_ipo_form">
                  {this.state.errorMessage && <Alert severity="error" className="errorMsd">{this.state.errorMessage}</Alert>}
                  <form onSubmit={this.handleSubmit} autoComplete="nope">
                    <div className="formW">
                      <TextField type="text" label="businessPartner" name="this.state.businessPartner" onChange={this.handlebusinessPartnerChange} />
                    </div>
                    {/* 
  <div className="formW">
    <TextField type="text" label="product" name="this.state.product" onChange={this.handleproductChange} />
  </div> */}
                    <div className="formW">
                      <FormControl required fullWidth>
                        <InputLabel>product</InputLabel>

                        <Select
                          native
                          value={this.state.handleproductChange}
                          onChange={this.handleproductChange}
                          name="handleproductChange"
                        >
                          <option aria-label="None" value="" />
                          <option value="ipo">ipo</option>
                          <option value="Demat Account">Demat Account</option>
                        </Select>
                      </FormControl>
                    </div>



                    <div className="formW">
                      <TextField type="text" label="ipoName" name="this.state.ipoName" onChange={this.handleipoNameChange} />
                    </div>
                    {/* <div className="formW">
     <TextField type="text" label="revenuePerApplicationList" name="this.state.revenuePerApplicationList"  autoComplete="nope" onChange={this.handlerevenueModelChange} />
  </div> */}

                    <div className="formW">
                      <FormControl required fullWidth>
                        <InputLabel>Revenue modal</InputLabel>

                        <Select
                          native
                          value={this.state.handlerevenueModelChange}
                          onChange={this.handlerevenueModelChange}
                          name="revenueModel"
                        >
                          <option aria-label="None" value="" />
                          <option value="revenuePerApplicationList">revenuePerApplicationList</option>
                          <option value="revenueHybridList">revenueHybridList</option>
                          <option value="revenueAllotmentBasisList">revenueAllotmentBasisList</option>
                        </Select>
                      </FormControl>
                    </div>



                    <div className="formW">
                      <TextField type="text" label="bidFrom" name="this.state.bidFrom" onChange={this.handlebidFromChange} autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="bidTo" name="this.state.bidTo" onChange={this.handlebidToChange} autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="upTo10x" name="this.state.upTo10x" onChange={this.handleupTo10xChange} autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="upTo20x" name="this.state.upTo20x" onChange={this.handleupTo20xChange} autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="upTo50x" name="this.state.upTo50x" onChange={this.handleupTo50xChange} autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="above50x" name="this.state.above50x" onChange={this.handleabove50xChange} autoComplete="nope" />
                    </div>
<br/><br/><br/><br/>





                    <br /><br /><br /><br />
                    {/* <button type="submit" color="primary">Add</button> */}
                    <div className="form-btn formW">
                      <Button
                        size="small"
                        variant="contained"
                        className=""
                        color="primary"
                        type="submit"
                        disableElevation
                      >
                        Add IPO
                      </Button>
                    </div>

                  </form>
                </ExpansionPanelDetails>


              </ExpansionPanel>
            </div>
            {/* <FaqPage/> */}
            <AgentMaster />



          </Grid>
        </div>
      </div>





    )
  }
}
export default revenueModalOne;
