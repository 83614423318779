// It will impliment the userListUseCase Interface here..
// export UserListUseCaseHanlder implements UserListUseCase;

import { UserListUseCase } from "../adapter/usecase/UserListsUseCase";

import ApiService from "../api/ApiService";
class UserListUseCaseHanlder implements UserListUseCase {


  getReasearchCallsData() {
    return (dispatch) => {
      dispatch({ type: "FETCH_USER_LIST_DATA" });

      ApiService.fetchAllData()
        .then((response) => {
          console.log(response, "test");
          dispatch({
            type: "FETCH_USER_LIST_DATA_SUCCESS",
            payload: response.data.response,
          });
        })
        .catch((error) => {
          dispatch({
            type: "FETCH_USER_LIST_DATA_ERROR",
            payload: error.response,
          });
        });
    };
  }

  // broker list 
  getbrokerCallsData() {
    return (dispatch) => {
      dispatch({ type: "FETCH_BROKER_LIST_DATA" });

      ApiService.allBrokerList()
        .then((response) => {
          console.log(response, "test");
          dispatch({
            type: "FETCH_BROKER_LIST_DATA_SUCCESS",
            payload: response.data.response.content,
          });
        })
        .catch((error) => {
          dispatch({
            type: "FETCH_BROKER_LIST_DATA_ERROR",
            payload: error.response,
          });
        });
    };
  }
  editBrokerCallsData(data: any) {
    return (dispatch) => {
      dispatch({ type: "ALL_BROKER_EDIT" });
      ApiService.AllBrokerEdit(data)
        .then((response) => {
          dispatch({
            type: "ALL_BROKER_EDIT_SUCCESS",
            payload: response.data.response,
          });
        })
        .catch((error) => {
          dispatch({
            type: "ALL_BROKER_EDIT_ERROR",
            payload: error.response,
          });
        });
    };
  }
 // broker list 



// guest list 
getguestCallsData(fromDate, toDate) {
  return (dispatch) => {
    dispatch({ type: "FETCH_BROKER_LIST_DATA" });

    ApiService.allGuestList(fromDate, toDate)
      .then((response) => {
        console.log(response, "test");
        dispatch({
          type: "FETCH_BROKER_LIST_DATA_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_BROKER_LIST_DATA_ERROR",
          payload: error.response,
        });
      });
  };
}

// broker list 












 
// add research

  addCallData(data: any) {
    return (dispatch) => {
      dispatch({ type: "ADD_USER_DATA" });
      ApiService.saveScripData(data)
        .then((response) => {
          if (response.data.statusCode === 200) {
            dispatch({
              type: "ADD_USER_DATA_SUCCESS",
              payload: response.data,
            });
          } else {
            dispatch({
              type: "ADD_USER_DATA_ERROR",
              payload: response.data.message,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: "ADD_USER_DATA_ERROR",
            payload: "Something Went Wrong",
          });
        });
    };
  }

  modifyResearchCall(data: any) {
    return (dispatch) => {
      dispatch({ type: "MODIFY_USER_DATA" });
      ApiService.modifyScripData(data)
        .then((response) => {
          dispatch({
            type: "MODIFY_USER_DATA_SUCCESS",
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: "MODIFY_USER_DATA_ERROR",
            payload: error.response,
          });
        });
    };
  }

  closeResearchCall(data: any) {
    return (dispatch) => {
      dispatch({ type: "CLOSE_USER_DATA" });
      ApiService.closeScripData(data)
        .then((response) => {
          dispatch({
            type: "CLOSE_USER_DATA_SUCCESS",
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: "CLOSE_USER_DATA_ERROR",
            payload: error.response,
          });
        });
    };
  }

  publishUserCall(data: any) {
    return (dispatch) => {
      dispatch({ type: "PUBLISH_USER_DATA" });
      ApiService.publishScripData(data)
        .then((response) => {
          dispatch({
            type: "PUBLISH_USER_DATA_SUCCESS",
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: "PUBLISH_USER_DATA_ERROR",
            payload: error.response,
          });
        });
    };
  }

  falseShowMessage() {
    return (dispatch) => {
      dispatch({
        type: "FALSE_SHOW_MESSAGE",
        payload: false,
      });
    };
  }


// ipo partner revenue system
getApiData() {
  return (dispatch) => {
    dispatch({ type: "FETCH_USER_LIST_DATA" });

    ApiService.fetchAllDataIPO()
      .then((response) => {
        console.log(response, "test");
        dispatch({
          type: "FETCH_USER_LIST_DATA_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_USER_LIST_DATA_ERROR",
          payload: error.response,
        });
      });
  };
}
modifyIPOCall(data: any) {
  return (dispatch) => {
    dispatch({ type: "MODIFY_IPO_DATA" });
    ApiService.modifyIPOData(data)
      .then((response) => {
        dispatch({
          type: "MODIFY_IPO_DATA_SUCCESS",
          payload: response.data.response,
        });
      })
      .catch((error) => {
        dispatch({
          type: "MODIFY_IPO_DATA_ERROR",
          payload: error.response,
        });
      });
  };
}




// ipo application 
getIpoApplicationApiData() {
  return (dispatch) => {
    dispatch({ type: "FETCH_USER_LIST_DATA" });

    ApiService.fetchAllApplicationApiData()
      .then((response) => {
        console.log(response, "test");
        dispatch({
          type: "FETCH_USER_LIST_DATA_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_USER_LIST_DATA_ERROR",
          payload: error.response,
        });
      });
  };
}
// ipo application 


















// revenue system module
getRevenueApiData() {
  return (dispatch) => {
    dispatch({ type: "FETCH_USER_LIST_DATA" });

    ApiService.fetchAllDatarevenue()
      .then((response) => {
        console.log(response, "test");
        dispatch({
          type: "FETCH_USER_LIST_DATA_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_USER_LIST_DATA_ERROR",
          payload: error.response,
        });
      });
  };
}
modifyRevenueCall(data: any) {
  return (dispatch) => {
    dispatch({ type: "MODIFY_IPO_DATA" });
    ApiService.modifyrevenueData(data)
      .then((response) => {
        dispatch({
          type: "MODIFY_IPO_DATA_SUCCESS",
          payload: response.data.response,
        });
      })
      .catch((error) => {
        dispatch({
          type: "MODIFY_IPO_DATA_ERROR",
          payload: error.response,
        });
      });
  };
}














}
export default new UserListUseCaseHanlder();
