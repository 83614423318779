import React, { useEffect, useState, useMemo, useLayoutEffect } from "react";
import { TableHeader, Pagination, Search } from "../DataTable";
import UserListUseCaseHanlder from "../../usecase/UserListUseCaseHandler";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Loader from "../Utils/Loader";
import CloseIcon from "@material-ui/icons/Close";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import TradeImportComponent from "../Ipo/AddLogo";
import {
  Grid,
  Paper,
  Button,
  TextField,
} from "@material-ui/core/";
import ApiService from "../../api/ApiService";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from '@material-ui/core/DialogContentText';

import axios from "axios";

const AgentMaster = (props) => {
  const [datas, setDatas] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [editaBtnClicked, setEditaBtnClicked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [resetForm, setResetform] = useState(false)
  const [exchange_Id, setExchange] = useState("")
  const [scripId, setScripId] = useState("")
  const [defaultData, setDefaultData] = React.useState({
    categoriesName: "",
    categoriesSegmentName: "",
    exchName: "",
    marketName: "",
    buySellInd: "",
  });
  const [notification, setNofication] = React.useState(true);
  const handlePublishChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNofication(event.target.checked);
  };

  const handleModifyChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNofication(event.target.checked);
  };
  const handleCloseChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNofication(event.target.checked);
  };




  const [isOpeningDialog, setisOpeningDialog] = React.useState(false);
  const [rowId, setRowId] = React.useState(null);

  const [isOpen, setisOpen] = React.useState(false);
  const handleDialogOpen = () => {
    setisOpen(true);
  };
  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [isOpenTwo, setisOpenTwo] = React.useState(false);
  const handleDialogOpenTwo = (e, id) => {
    setisOpenTwo(true);
    setRowId(id);
  };


  const [isOpenThree, setisOpenThree] = React.useState(false);
  const handleDialogOpenThree = (e, id) => {
    setisOpenThree(true);
    setRowId(id);
  };



  const handleDialogCloseTwo = () => {
    setisOpenTwo(false);
  };
  const handleDialogCloseThree = () => {
    setisOpenThree(false);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [editableData, setEditableData] = useState({

    id: "",
    legalNameOfEntity: '',
    brandName: '',
    gstNo: '',
    address: '',
    contactPersonName: '',
    contactPersonEmail: '',
    contactPersonContact: '',
    website: '',
    androidAppUrl: '',
    iosAppUrl: '',
    integrationType: '',
    logo: '',
  });




  const [userMetaInfo, setUserMetaInfo] = useState({
    addCall: "",
    adminRollType: "",
    closedCall: "",
    createdAt: "",
    modifyCall: "",
    publishCall: "",
  });


  const [meta, setMeta] = useState(false);

  const ITEMS_PER_PAGE = 10;

  const { allDatas, fetchedUserList, statusCode, message } = useSelector(
    (state: any) => ({
      allDatas: state.userListsReducer.allData,
      fetchedUserList: state.userListsReducer.fetchedUserList,
      message: state.userListsReducer.message,
      statusCode: state.userListsReducer.statusCode,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

 

  useEffect(() => {
    dispatch(UserListUseCaseHanlder.getRevenueApiData());
  }, [message]);

  useEffect(() => {
    setDatas(allDatas);
  }, [allDatas]);

  useEffect(() => {
    let username = localStorage.getItem("username");
  }, []);

  const editUser = (data: any) => {
    // alert(JSON.stringify(data));
    setOpen(true);
    setEditaBtnClicked(true);
    setEditableData(data);
  };

  const handleChange = (e: any) => {
    setEditableData({ ...editableData, [e.target.name]: e.target.value });
  };


  // const [StateVariable, setStateVariable] = useState({
  //   symbol: "",
  //   scripId: "",
  //   exchange: ""
  // })
  // const handleSearchScripName = (scripData) => {
  //   console.log('scrpt', scripData)
  //   setExchange(scripData.exchange)
  //   setScripId(scripData.scripId)
  //   // setStateVariable({
  //   //   symbol: scripData.symbol,
  //   //   scripId: scripData.scripId,
  //   // });
  // };

  const API_ENDPOINT = "https://jsonplaceholder.typicode.com/posts";
  const [readPostId, writePostId] = useState("");
  const [readStatus, writeStatus] = useState("");
  const updatePostId = (e) => writePostId(readPostId);
  const deletePost = async (e) => {
    e.preventDefault();
    try {
      await axios.delete(`${API_ENDPOINT}/${readPostId}`);
      writeStatus("Post successfully deleted");
      setTimeout(() => writeStatus(""), 3000);
    } catch (err) {
      writeStatus("Post deletion failed");
    }
  };




  const toggleReset = (value) => {
    setResetform(value)
  };









  const handleSubmit = (e: any) => {
    e.preventDefault();
    // alert(editableData);
    let data = {
      id: editableData.id,
      legalNameOfEntity: editableData.legalNameOfEntity,
      brandName: editableData.brandName,
      gstNo: editableData.gstNo,
      address: editableData.address,
      contactPersonName: editableData.contactPersonName,
      contactPersonEmail: editableData.contactPersonEmail,
      contactPersonContact: editableData.contactPersonContact,
      website: editableData.website,
      androidAppUrl: editableData.androidAppUrl,
      iosAppUrl: editableData.iosAppUrl,
      integrationType: editableData.integrationType,
      logo: editableData.logo,
    };
    console.log(data, "modified data");
    modifyCall(data);
    setEditableData({
      id: "",
      legalNameOfEntity: '',
      brandName: '',
      gstNo: '',
      address: '',
      contactPersonName: '',
      contactPersonEmail: '',
      contactPersonContact: '',
      website: '',
      androidAppUrl: '',
      iosAppUrl: '',
      integrationType: '',
      logo: '',
    });
    setNofication(true);

  };


  const modifyCall = (data: any) => {
    dispatch(UserListUseCaseHanlder.modifyRevenueCall(data));
    setOpen(false);
  };
  const addDefaultSrc = (e) => {
    e.target.src = "https://invest19.com/notfound.png";
    e.target.onerror = null;
  };




  const headers = [
    // { name: "ID", field: "id", sortable: true },
    { name: "business Partner", field: "businessPartner", sortable: true },
    { name: "product", field: "product", sortable: false },
    { name: "ipo Name", field: "ipoName", sortable: false },
    { name: "revenue Model", field: "revenueModel", sortable: false },
    { name: "", field: "", sortable: false },
  ];

  const datasData = useMemo(() => {
    let computedDatas = datas;
    if (computedDatas) {
      if (search) {
        computedDatas = computedDatas.filter(
          (data: any) =>
            data.symble.toLowerCase().includes(search.toLowerCase()) ||
            data.companyName.toLowerCase().includes(search.toLowerCase())
        );
      }
      setTotalItems(computedDatas.length);
    }

    //Sorting datas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedDatas = computedDatas.sort(
        (a: any, b: any) =>
          reversed *
          a[sorting.field]
            .toString()
            .localeCompare(b[sorting.field], undefined, {
              numeric: true,
            })
      );
    }

    //Current Page slice
    return computedDatas && computedDatas.length && computedDatas.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [datas, currentPage, search, sorting]);


  const clickHandle = () => {
    window.location.reload();
    // window.confirm("Hello World!");
    // console.log('You clicked the button')
  }



  //logo
  const onUpload = (url) => {
    setEditableData((oldValue) => {
      return { ...oldValue, logo: url };
    })
  }
  //logo

  return (

    <>
      <div className="searchUpdatesectin noneS">
        <Search
          onSearch={(value) => {
            setSearch(value);
            setCurrentPage(1);
          }}
        />
      </div>
      <Paper className="tableP">
        <Grid container className="listContainer ipolisting newUiupadte">
          <Table stickyHeader>
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <TableBody>
              {fetchedUserList ? (
                datasData && datasData.map((data: any) => (
                  <TableRow id={data.partnerId} key={data.partnerId} className={data.partnerId}>
                    <TableCell align="left">{data.businessPartner}</TableCell>
                    <TableCell align="left">{data.product}</TableCell>
                    <TableCell align="left">{data.ipoName}</TableCell>
                    <TableCell align="left">{data.revenueModel}</TableCell>
                    <TableCell align="left">
                      {/* <form onSubmit={deletePost}>
                          <input onChange={updatePostId} value={data.id} />
                          <input type="submit" value="Delete" />
                        </form> */}
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              setOpen(true);
                              setEditaBtnClicked(true);
                              setEditableData(data);
                            }}
                          >
                            <EditRoundedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        {/* <Button variant="outlined" color="primary" >
        Open simple dialog
      </Button> */}
                        <Dialog open={isOpen} onClose={handleDialogClose}>
                          {/* <DialogTitle>Confirm</DialogTitle> */}
                          <DialogContent>
                            <DialogContentText>Are you sure?</DialogContentText>
                          </DialogContent>
                          <DialogActions className="basic-popupbtn">

                            <Button onClick={handleDialogClose} color="primary">
                              no
                            </Button>
                            {/* <Button onClick={(event) => { removeData(data.id); handleDialogClose(); clickHandle(); }} color="primary" autoFocus>
                            yes
                          </Button> */}
                          </DialogActions>
                        </Dialog>
                      </TableCell>

                  </TableRow>

                ))

              ) : (

                <Loader />

              )}
            </TableBody>
          </Table>



          <div>

            {/* <div className="align-right">
        <Pagination
          total={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div> */}


            {editaBtnClicked && editableData ? (
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  <span>Do you want to Modify the IPO {editableData.legalNameOfEntity} ? </span>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                      <CloseIcon />
                    </Button>
                  </DialogActions>
                </DialogTitle>

                <DialogContent>
                  <Grid container className="addDatasection">

                    <form
                      style={formContainer}
                      onSubmit={handleSubmit}
                      id="modifyipo"
                      autoComplete="off"
                    >
                      <div className="formW intop">
                        <TextField
                          label="legalNameOfEntity"
                          type="text"
                          fullWidth
                          margin="normal" onChange={handleChange}
                          name="legalNameOfEntity"
                          value={editableData.legalNameOfEntity}
                        />
                      </div>
                      <div className="formW intop">
                        <TextField
                          label="brandName"
                          fullWidth
                          type="text" onChange={handleChange}
                          margin="normal"
                          name="brandName"
                          value={editableData.brandName}

                        />
                      </div>


                      <div className="formW intop">
                        <TextField type="text" label="gstNo" name="gstNo" onChange={handleChange} value={editableData.gstNo} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="address" name="address" onChange={handleChange} value={editableData.address} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="contactPersonName" name="contactPersonName" onChange={handleChange} value={editableData.contactPersonName} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="contactPersonEmail" name="contactPersonEmail" onChange={handleChange} value={editableData.contactPersonEmail} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="contactPersonContact" name="contactPersonContact" onChange={handleChange} value={editableData.contactPersonContact} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="website" name="website" onChange={handleChange} value={editableData.website} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="androidAppUrl" name="androidAppUrl" onChange={handleChange} value={editableData.androidAppUrl} />
                      </div>
                      <div className="formW">
                        <TextField type="text" label="iosAppUrl" name="iosAppUrl" onChange={handleChange} value={editableData.iosAppUrl} />
                      </div>
                      <div className="formW">
                        <TextField type="number" label="integrationType" name="integrationType" onChange={handleChange} value={editableData.integrationType} />
                      </div>

                      <div className="formW dNoneP">
                        <TextField type="text" label="logo" name="logo" onChange={handleChange} value={editableData.logo} />
                      </div>

                      <br/><br/> <br/><br/> 
                      <div className="inUpload">
                        <div className="formW">
                          <label>Upload Logo</label>
                          <TradeImportComponent upload={onUpload} />
                        </div>

                      </div>

                      <br />

                      <div style={{ width: "100%" }} className="btnMar">
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          value="submit"
                          // onClick={(event) => { clickHandle();}}
                          disableElevation
                        >
                          Yes
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleClose}
                        >
                          No
                        </Button>
                      </div>
                    </form>

                  </Grid>
                </DialogContent>
              </Dialog>
            ) : null}
          </div>
        </Grid>
      </Paper>
      <div className="align-right">
        <Pagination
          total={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          // currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};
const formContainer = {
  display: "flex",
  flexFlow: "row wrap",
};

export default AgentMaster;


