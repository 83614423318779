import React from 'react';
import axios from 'axios';
// import { Form } from 'antd';
// import { List, Card, Form } from 'antd';
import { Grid, Button, MenuItem } from "@material-ui/core/";
import Alert from '@material-ui/lab/Alert';
// const FormItem = Form.Item;
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import TradeImportComponent from "../Ipo/AddLogo";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import AgentMaster from "../Ipo/AgentMaster";
import { FormControl } from "@material-ui/core/";
import InputLabel from "@material-ui/core/InputLabel";
import { Select } from "@material-ui/core/";
// import AutoCompleteScrip from "../User/AutoCompleteScrip";
class ipoUser extends React.Component<{}, any>{
  // export default class ipo extends React.Component {

  custom_file_upload_url = `https://gatewayapi.invest19.com/api/ipo/document/upload`;
  canvas: any;

  constructor(props) {
    super(props)
    this.state = { checked: false };
    this.canvas = React.createRef()
    this.state = {
      legalNameOfEntity: '',
      userName: '',
      password: '',
      brandName: '',
      gstNo: '',
      address: '',
      contactPersonName: '',
      contactPersonEmail: '',
      contactPersonContact: '',
      website: '',
      androidAppUrl: '',
      iosAppUrl: '',
      integrationType: '',
      logo: '',
      partnerType: '',
      commissionType: '',


      image_file: null,
      image_preview: '',
      // usergroup: '',
      // emailid: '',
      // mobile: '',
      // title: '',

    }
  }
  componentDidUpdate() {
    setTimeout(() => this.setState({ errorMessage: '' }), 40000);
    setTimeout(() => this.setState({ sucessMessage: '' }), 40000);
  }



  handleCmpdData = (data) => {
    this.setState({
      currentMarketPrice: data,
    });
  };
  toggleReset = (value) => {
    this.setState({
      resetForm: value,
    });
  };
  // Image Preview Handler
  handleImagePreview = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0])
    let image_as_files = e.target.files[0];

    this.setState({
      image_preview: image_as_base64,
      image_file: image_as_files,
    })
  }

  // Image/File Submit Handler
  handleSubmitFileUpload = () => {

    if (this.state.image_file !== null) {

      let formData = new FormData();
      formData.append('ipoName', 'tata');
      formData.append('document', this.state.image_file);

      axios.post(
        this.custom_file_upload_url,
        formData,
        {
          headers: {
            // "Authorization": "YOUR_API_AUTHORIZATION_KEY_SHOULD_GOES_HERE_IF_HAVE",
            "Content-type": "multipart/form-data",
          },
        }
      )
        .then(res => {
          console.log(`Success` + res.data);
          var json = JSON.parse(JSON.stringify(res.data));
          console.log(json);
          console.log(json.url);
          console.log(this.canvas.current.value);
          // this.canvas.current.value=json.url;
          // console.log(this.canvas.current.value);
          return json.url;
        })
        .catch(err => {
          console.log(err);
        })
    }
  }



  uploadDocument = (e) => {
    console.log(e);
  }


  handlelegalNameOfEntityChange = event => { this.setState({ legalNameOfEntity: event.target.value }) }
  handleuserNameChange = event => { this.setState({ userName: event.target.value }) }
  handlepasswordChange = event => { this.setState({ password: event.target.value }) }
  handlebrandNameChange = event => { this.setState({ brandName: event.target.value }) }
  handlegstNoChange = event => { this.setState({ gstNo: event.target.value }) }
  handleaddressChange = event => { this.setState({ address: event.target.value }) }
  handlecontactPersonNameChange = event => { this.setState({ contactPersonName: event.target.value }) }
  handlecontactPersonEmailChange = event => { this.setState({ contactPersonEmail: event.target.value }) }
  handlecontactPersonContactChange = event => { this.setState({ contactPersonContact: event.target.value }) }
  handlewebsiteChange = event => { this.setState({ website: event.target.value }) }
  handleandroidAppUrlChange = event => { this.setState({ androidAppUrl: event.target.value }) }
  handleiosAppUrlChange = event => { this.setState({ iosAppUrl: event.target.value }) }
  handleintegrationTypeChange = event => { this.setState({ integrationType: event.target.value }) }
  handlelogo = event => { this.setState({ logo: event.target.value }) }
  handlepartnerType = event => { this.setState({ partnerType: event.target.value }) }
  handlecommissionType = event => { this.setState({ commissionType: event.target.value }) }


  // handleapplicationSeriesApplied  =   event => {this.setState({ applicationSeriesApplied: event.target.value })}

  handleapplicationSeriesApplied = () => {
    this.setState({ applicationSeriesApplied: !this.state.false });
  }
  // handleabout_Company = event => {this.setState({ about_Company: event.target.value })}

  handleSubmit = event => {
    event.preventDefault();
    event.target.reset()

    // const openDate = {userid: this.state.openDate};
    // const closeDate = {fullname: this.state.closeDate};
    // const listingDate = {usergroup: this.state.listingDate};


    axios.post('https://gatewayapi-uat.invest19.com/super/add/new/business/partner',
      {
        userName: this.state.userName,
        password: this.state.password,
        legalNameOfEntity: this.state.legalNameOfEntity,
        brandName: this.state.brandName,
        gstNo: this.state.gstNo,
        address: this.state.address,
        contactPersonName: this.state.contactPersonName,
        contactPersonEmail: this.state.contactPersonEmail,
        contactPersonContact: this.state.contactPersonContact,
        website: this.state.website,
        androidAppUrl: this.state.androidAppUrl,
        iosAppUrl: this.state.iosAppUrl,
        integrationType: this.state.integrationType,
        logo: this.state.logo,
        partnerType: this.state.partnerType,
        commissionType: this.state.commissionType,
      },

    )

      .then(res => {
        console.log(res);
        console.log(res.data);
        console.log('thank you')
        this.setState({ sucessMessage: res.data });
      })
      .catch(err => {
        this.setState({ errorMessage: err.message });
      })
  }


  onUpload = (url) => {
    console.log('I have been clicked', url);
    this.setState({ logo: url })
  }


  render() {

    const callbacks = { upload: this.onUpload };
    return (
      <div className="CoomonT mainCt">
        <div className="innerprofilesection">
          <Grid className="CoomonT">
            <div className="clapse ">
              <ExpansionPanel className="newUiupadte">
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Button
                    size="small"
                    variant="contained"
                    className="changeBTN"
                    color="primary"
                    disableElevation
                  >
                    Add New Partner
                  </Button>
                </ExpansionPanelSummary>


                <ExpansionPanelDetails className="inner_ipo_form">
                  {this.state.errorMessage && <Alert severity="error" className="errorMsd">{this.state.errorMessage}</Alert>}
                  <form onSubmit={this.handleSubmit} autoComplete="nope">
                    <div className="formW">
                      <TextField type="text" label="user Name" name="this.state.userName" onChange={this.handleuserNameChange} required autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="password" name="this.state.password" onChange={this.handlepasswordChange} required autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="legalNameOfEntity" name="this.state.legalNameOfEntity" onChange={this.handlelegalNameOfEntityChange} required autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="brandName" name="this.state.brandName" onChange={this.handlebrandNameChange} required autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="gstNo" name="this.state.gstNo" onChange={this.handlegstNoChange} autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="address" name="this.state.address" autoComplete="nope" onChange={this.handleaddressChange} />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="contactPersonName" name="this.state.contactPersonName" onChange={this.handlecontactPersonNameChange} required autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="email" label="contactPersonEmail" name="this.state.contactPersonEmail" onChange={this.handlecontactPersonEmailChange} autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="number" label="contactPersonContact" name="this.state.contactPersonContact" onChange={this.handlecontactPersonContactChange} autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="website" name="this.state.website" onChange={this.handlewebsiteChange} autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="androidAppUrl" name="this.state.androidAppUrl" onChange={this.handleandroidAppUrlChange} autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="iosAppUrl" name="this.state.iosAppUrl" onChange={this.handleiosAppUrlChange} autoComplete="nope" />
                    </div>
                    <div className="formW">
                      <TextField type="text" label="integrationType" name="this.state.integrationType" onChange={this.handleintegrationTypeChange} autoComplete="nope" />
                    </div>

                    <div className="dNone">
                      <TextField type="hidden" label="logo" name="this.state.logo" value="" onChange={this.handlelogo} required />
                    </div>
                    <div className="formW">
                      <FormControl required fullWidth>
                        <InputLabel>Partner Type</InputLabel>

                        <Select
                          native
                          value={this.state.handlepartnerType}
                          onChange={this.handlepartnerType}
                          name="handlepartnerType"
                        >
                          <option aria-label="None" value="" />
                          <option value="IpoPwaModel">IPO PWA Model</option>
                          <option value="FcmUrlModel">FCM URL Model</option>
                          <option value="ApiModel">API Model</option>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="formW">
                      <FormControl required fullWidth>
                        <InputLabel>commissionType</InputLabel>

                        <Select
                          native
                          value={this.state.handlecommissionType}
                          onChange={this.handlecommissionType}
                          name="handlecommissionType"
                        >
                          <option aria-label="None" value="" />
                          <option value="Only to referred products">Only to referred products</option>
                          <option value="To all product">To all product</option>
                        </Select>
                      </FormControl>
                    </div>
                    {/* <div className="formW">
<TextField type="text" label="about Company" name="this.state.about_Company" onChange={this.handleabout_Company} />
  </div> */}







                    <div className="formW logoUp">
                      <label>Upload Logo</label>
                      <TradeImportComponent {...callbacks} required />
                    </div>







                    {/* <div>file<TradeImportComponent/></div> */}
                    <br /><br /><br /><br />
                    {/* <button type="submit" color="primary">Add</button> */}
                    <div className="form-btn formW">
                      <Button
                        size="small"
                        variant="contained"
                        className=""
                        color="primary"
                        type="submit"
                        disableElevation
                      >
                        Add Partner
                      </Button>
                    </div>

                  </form>
                </ExpansionPanelDetails>


              </ExpansionPanel>
            </div>
            {/* <FaqPage/> */}
            <AgentMaster />



          </Grid>
        </div>
      </div>





    )
  }
}
export default ipoUser;
