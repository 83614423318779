import React, { useEffect, useState } from "react";
import Pagination from '@material-ui/lab/Pagination';

const PaginationComponent = ({
  total = 0,
  itemsPerPage = 10,
  onPageChange,
}) => {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (total > 0 && itemsPerPage > 0)
      setTotalPages(Math.ceil(total / itemsPerPage));
  }, [total, itemsPerPage]);

  const pageChange = (e, pageNumber) => {
    onPageChange(pageNumber)
  }

  if (totalPages === 0) return null;

  return (
    <div className="pagination_Ul">
    <Pagination className="paginationInfo" count={totalPages}  onChange={pageChange} color="primary" showFirstButton showLastButton />
    </div>
    
  );
};

export default PaginationComponent;
