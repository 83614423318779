import React, { useEffect } from "react";
import "./App.scss";
import { BrowserRouter as Router } from "react-router-dom";
import FooterComponent from "./component/Layout/FooterComponent";
import WrapperComponent from "./component/Layout/WrapperComponent";

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <WrapperComponent />
      </Router>
    </div>
  );
};

export default App;
