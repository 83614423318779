import ApiService from "../api/ApiService";

import { UserLoginUseCase } from "../adapter/usecase/UserLoginUseCase";

class UserLoginUseCaseHandler implements UserLoginUseCase {
  userLogin = (data) => {
    return (dispatch) => {
      console.log(data, "dtsdfsdf");
      // debugger;
      dispatch({ type: "USER_LOGIN" });
      ApiService.researchLogin(data)
      .then((response) => {
        if (response.data.body.statusOfRequest.statusCode === "0") {
          dispatch({
            type: "USER_LOGIN_SUCCESS",
            payload: response.data,
          });
          console.log(response.data);
        } else {
          dispatch({
            type: "USER_LOGIN_FAILURE",
            payload: "Invalid userId/Password",
          });
        }
      })
        .catch((error) => {
          dispatch({
            type: "USER_LOGIN_FAILURE",
            payload: "Some Problem occurred",
          });
        });
    };
  };

  userLogout = (data) => {
    window.localStorage.clear();
    return (dispatch) => {
      dispatch({
        type: "USER_LOGOUT_SUCCESS",
        payload: data,
      });
    };
  };

  showMessage = (data) => {
    return (dispatch) => {
      dispatch({
        type: "SHOW_MESSAGE_SUCCESS",
        payload: data,
      });
    };
  };
}
export default new UserLoginUseCaseHandler();
