import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";

import IconDashboard from "@material-ui/icons/Dashboard";
import AppMenuItem from "./AppMenuItem";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";


const appMenuItems = [

  // {
  //   name: "Broker List",
  //   Icon: InfoOutlinedIcon,
  //   items: [
  //     {
  //       name: "Achiivers",
  //       link: "/dashboard",
  //     },
  //     {
  //       name: "StockPlus",
  //       link: "/StockPlus",
  //     },
  //     {
  //       name: "Aliceblue",
  //       link: "/Aliceblue",
  //     },


  //   ],
  // },

  {
    name: "Broker List",
    link: "/",
    Icon: IconDashboard,
  },
  {
    name: "Guest User List",
    link: "/GuestUserList",
    Icon: IconDashboard,
  },
  {
    name: "Partner",
    link: "/Partner",
    Icon: IconDashboard,
  },
  {
    name: "Add Revenue",
    link: "/revenueModalOne",
    Icon: IconDashboard,
  },
  {
    name: "Application List",
    link: "/ApplicationList",
    Icon: IconDashboard,
  },
  // {
  //   name: "Onboarding Dashboard",
  //   link: "/OnboardingDashboard",
  //   Icon: IconDashboard,
  // },
  // {
  //   name: "broker List",
  //   link: "/brokerList",
  //   Icon: IconDashboard,
  // },
  // {
  //   name: "Add new broker",
  //   link: "/",
  //   Icon: IconDashboard,
  // },


  // {
  //   name: "Create New admin",
  //   link: "/callhistory",
  //   Icon: IconDashboard,
  // },
  // {
  //   name: "Reset Broker Password",
  //   link: "/ChangeBrokerPassword",
  //   Icon: IconDashboard,
  // },
  // {
  //   name: "Reset Admin Password",
  //   link: "/ChangeAdminPassword",
  //   Icon: IconDashboard,
  // },
  // {
  //   name: "Downlaod Broker Master",
  //   link: "/DownloadAdminMaster",
  //   Icon: IconDashboard,
  // },

  // {
  //   name: "Referral",
  //   Icon: InfoOutlinedIcon,
  //   items: [
  //     {
  //       name: "Agent master",
  //       link: "/AgentMaster",
  //     },
  //     {
  //       name: "Generate Referral Code",
  //       link: "/ReferralMaster",
  //     }
  //   ],
  // },
  // {
  //   name: "Billing",
  //   Icon: InfoOutlinedIcon,
  //   items: [
  //     {
  //       name: "Invoices",
  //       link: "/InvoiceMaster",
  //     }
  //   ],
  // },



  {
    name: "Logout",
    link: "/logout",
    Icon: ExitToAppIcon,
  },
];

const AppMenu: React.FC = () => {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {/* <AppMenuItem {...appMenuItems[0]} /> */}
      {appMenuItems.map((item, index) => (
        <AppMenuItem {...item} key={index} />
      ))}
    </List>

  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: "100%",
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: "#97c05c",
    },
  })
);

export default AppMenu;
