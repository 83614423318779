import React, { ReactElement } from "react";
import { Divider } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Paper } from "@material-ui/core/";
import { withRouter } from "react-router-dom";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    usernmae: {
      alignItems: "center",
      backgroundColor: "transparent",
      color: "white",
      boxShadow: "none",
    },
  })
);

function UserLoginInfoComponent({ history }): ReactElement {
  const classes = useStyles();
  let user;
  let dateTime;
  history.listen((location, action) => {});
  user = localStorage.getItem("username");
  if (user !== null) {
    user = user.slice(1, -1);
  }
  dateTime = localStorage.getItem("lastLogin");
  if (dateTime !== null) {
    dateTime = dateTime.slice(1, -1);
  }
  return (
    <div className="userinfo-component">
      <Divider />
      <div className="userInfo">
        <AccountCircleIcon />
        <Paper className={classes.usernmae}>{user}</Paper>
        <p>Last Login: {dateTime}</p>
      </div>
      <Divider />
    </div>
  );
}
export default withRouter(UserLoginInfoComponent);
