import React from "react";
import { Link, Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import UserLoginUseCaseHandler from "../../usecase/UserLoginUseCaseHandler";
import { connect } from "react-redux";
class LogOutComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.logout(1);
  }

  render() {
    if (this.props.location.pathname === "/logout") {
      window.localStorage.clear();
      return <Redirect to="/login" />;
    }
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: (data) => dispatch(UserLoginUseCaseHandler.userLogout(data)),
  };
};
export default connect(null, mapDispatchToProps)(LogOutComponent);
