import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
const Header = ({ headers, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    //  alert(field);
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map(({ name, field, sortable }) => (
          <TableCell
            style={{ fontWeight: "bold", cursor: "pointer" }}
            key={name}
            onClick={() => (sortable ? onSortingChange(field) : null)}
          >
            {name}

            {sortingField &&
              sortingField === field &&
              (sortingOrder === "asc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              ))}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default Header;
