import React, { useEffect, useState, useMemo, useLayoutEffect } from "react";
import { TableHeader, Pagination, Search } from "../../DataTable";
import UserListUseCaseHanlder from "../../../usecase/UserListUseCaseHandler";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Loader from "../../Utils/Loader";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import Moment from "react-moment";
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const GuestUserList = (props) => {
  const [datas, setDatas] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const ITEMS_PER_PAGE = 10;

  const { allDatas, fetchedUserList, statusCode, message } = useSelector(
    (state: any) => ({
      allDatas: state.userListsReducer.allData,
      fetchedUserList: state.userListsReducer.fetchedUserList,
      message: state.userListsReducer.message,
      statusCode: state.userListsReducer.statusCode,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();




  useEffect(() => {
    dispatch(UserListUseCaseHanlder.getguestCallsData(fromDate, toDate));
  }, [fromDate, toDate]);

  useEffect(() => {
    setDatas(allDatas);
  }, [allDatas]);

  useEffect(() => {
    let username = localStorage.getItem("username");
  }, []);




  const handleDatetochange = (e) => {
    setToDate(e.target.value);
  };

  const handleDatefromchange = (e) => {
    setFromDate(e.target.value);
  };





  const headers = [
    { name: "Mobile", field: "Mobile", sortable: true },
    { name: "Progress Status", field: "progressOption", sortable: true },
    { name: "client bow id", field: "client_bow_id", sortable: false },
    // { name: "client inv19 id", field: "client_inv19_id", sortable: false },
    { name: "broker name", field: "broker_name", sortable: true },
    { name: "Last Login Date", field: "last_login_date", sortable: true },
    { name: "Last Login Time", field: "last_login_date", sortable: true },
    { name: "Login Date", field: "login_date", sortable: true },
    // { name: "Login Time", field: "login_date", sortable: true },
    { name: "leed code", field: "leed_code", sortable: false },
    { name: "source code", field: "source_code", sortable: false },
    { name: "user source", field: "user_source", sortable: true },
  ];

  const datasData = useMemo(() => {
    let computedDatas = datas;
    if (computedDatas) {
      if (search) {
        computedDatas = computedDatas.filter(
          (data: any) =>
            data.symble.toLowerCase().includes(search.toLowerCase()) ||
            data.company_Name.toLowerCase().includes(search.toLowerCase())
        );
      }
      setTotalItems(computedDatas.length);
    }

    //Sorting datas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedDatas = computedDatas.sort(
        (a: any, b: any) =>
          reversed *
          a[sorting.field]
            .toString()
            .localeCompare(b[sorting.field], undefined, {
              numeric: true,
            })
      );
    }

    //Current Page slice
    return computedDatas.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [datas, currentPage, search, sorting]);



  return (
    <div className="CoomonT mainCt">
  <div className="innerprofilesection">
      <Grid className="CoomonT">
        <div className="clapse ">

    <>
      {/* <Search
        onSearch={(value) => {
          setSearch(value);
          setCurrentPage(1);
        }}
      /> */}
      <div className="date-filter searchUpdatesectin">
        <TextField
          id="fromdate"
          label="From date"
          type="date"
          value={fromDate}
          onChange={handleDatefromchange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="todate"
          label="to date"
          type="date"
          value={toDate}
          onChange={handleDatetochange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <Paper className="tableP">

        <Grid container className="listContainer">

          <Table stickyHeader>
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <TableBody>
              {fetchedUserList ? (
                datasData.map((data: any) => (
                  <TableRow className={data.actions} key={data.clientIn19Id} id={data.clientIn19Id}>
                    <TableCell align="left">{data.mobile}</TableCell>
                    <TableCell align="left">
                      {/* {data.progressOption} */}
                    <div className="progressT">
      <LinearProgressWithLabel value={data.progressOption} />
    </div>
                    </TableCell>
                    <TableCell align="left">{data.client_bow_id}</TableCell>
                    {/* <TableCell align="left">{data.client_inv19_id}</TableCell> */}
                    <TableCell align="left">
                      <div style={{width:'200px'}}>{data.broker_name}</div>
                      </TableCell>
                    <TableCell align="left">
                      <Moment interval={30000} format="ll">
                        {data.last_login_date}
                      </Moment>
                    </TableCell>
                    <TableCell align="left">
                      <Moment interval={30000} format="LTS">
                        {data.last_login_date}
                      </Moment>
                    </TableCell>
                    <TableCell align="left">
                      <Moment interval={30000} format="ll">
                        {data.login_date}
                      </Moment>
                    </TableCell>
                    {/* <TableCell align="left">
                      <Moment interval={30000} format="LTS">
                        {data.login_date}
                      </Moment>
                    </TableCell> */}
                    <TableCell align="left">
                        {data.leed_code}
                    </TableCell>
                    <TableCell align="left">
                        {data.source_code}
                    </TableCell>
                    <TableCell align="left">
                        {data.user_source}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <span className="loader-body">
                  <Loader />
                </span>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Paper>
      <div className="align-right">
        <Pagination
          total={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>

    </div>
          </Grid>
          </div>
          </div>
  );
};


export default GuestUserList;

