import React, { Component } from 'react';
import axios from "axios";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import IconButton from "@material-ui/core/IconButton";

class TradeImportComponent extends React.Component<any,any>{

  
  onUpload(url: any) {
    throw new Error('Method not implemented.');
  }

    // API Endpoints
    custom_file_upload_url = `https://gatewayapi.invest19.com/broker/document/upload`;

  canvas: any;

    // state
    constructor(props) {
      super(props);
      this.canvas = React.createRef()
      this.state = {
          image_file: null,
          image_preview: '',
          uploadURL:'',
          url:''
      }
}  

    // Image Preview Handler
    handleImagePreview = (e) => {
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];

        this.setState({
            image_preview: image_as_base64,
            image_file: image_as_files,
        })
    }

    // Image/File Submit Handler
    handleSubmitFile = () => {
      if (this.state.image_file !== null){

          let formData = new FormData();
          formData.append('brokerName', 'broker');
          formData.append('document', this.state.image_file);
          var ref= this;
          axios.post(
              this.custom_file_upload_url,
              formData,
              {
                  headers: {
                      // "Authorization": "YOUR_API_AUTHORIZATION_KEY_SHOULD_GOES_HERE_IF_HAVE",
                      "Content-type": "multipart/form-data",
                  },                    
              }
          )
          .then(res => {
              console.log(`Success` + res.data.response);
              var json= JSON.parse(JSON.stringify(res.data.response));
              console.log(ref.props);
              ref.props.upload(json.url);
          })
          .catch(err => {
              console.log(err);
          })
      }
  }
 
   addDefaultSrc = (e) => {
    e.target.src="https://invest19.com/notfound.png";
    e.target.onerror = null;
  };

    // componentDidMount() {
    //   axios.post(`https://gatewayapi.invest19.com/api/ipo/document/upload`)
    //     .then(res => {
    //       const persons = res.data.response;
    //       this.setState({ persons });
    //     })
    // }

    // render from here
    render() { 
        return (
            <div>
                {/* image preview */}
                {/* <img src={this.state.image_preview} className="previewImg" onError={this.addDefaultSrc} alt="image_preview"/> */}
                {/* {this.state.url} */}
                {/* image input field */}
                <input
                    type="file"
                    onChange={this.handleImagePreview}
                    
                />
                {/* <input value={this.state.uploadUrl}/> */}
                {/* <input type="hidden" name="logo" id="uploadlogo"/>
                <input type="hidden" name="rhp" value="test@1234567" ref={this.canvas} id="uploadRhp"/>
                <input type="hidden" name="financial_Pdf" id="uploadfinancial"/> */}

                {/* <label>Upload file</label> */}
                {/* <input type="submit" onClick={this.handleSubmitFile} value="Submit"/> */}
                <IconButton onClick={this.handleSubmitFile}>
                    <CheckRoundedIcon/>
                    </IconButton>

            </div>
        );
    }
}

export default TradeImportComponent;
